import { useParams } from 'react-router-dom';
import { paymentService } from '../../services/paymentService';
import Button from '../../components/Button';

const PAYMENT_REDIRECT_URL = process.env.REACT_APP_PAYMENT_REDIRECT_URL || ''
const WOMPI_URL = 'https://checkout.wompi.co/p/'
const WOMPI_PUBLIC_KEY = process.env.REACT_APP_WOMPI_PUBLIC_KEY || ''

const StartPayment = () => {


  let { token } = useParams<"token">();
  const handleContinue = () => {
    if (token === undefined) {
      return
    }
    paymentService.getPaymentMetadata(
      token,
      response => {
        const params = new URLSearchParams()
        params.append('public-key', WOMPI_PUBLIC_KEY)
        params.append('currency', response.currency)
        params.append('amount-in-cents', response.amount_in_cents.toString())
        params.append('reference', response.reference)
        params.append('signature:integrity', response.signature)
        params.append('redirect-url', PAYMENT_REDIRECT_URL)
        const url = `${WOMPI_URL}?${params.toString()}`
        window.location.href = url;
      },
      error => {
        console.log(error)
        alert('Error al generar el pago')
      },
    )
  };

  return <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full text-center">
      <h2 className='text-xl font-rounded font-bold pb-3' id="modal-title">Verificación de Correo Electrónico</h2>
      <p className='text-lg font-rounded  pb-3' id="modal-description">Su correo ha sido verificado ✅</p>

      <Button
        text="Continuar con el pago"
        onClick={handleContinue}
      />
    </div>
  </div>;
}

export default StartPayment;
