import { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { employeesService, EmployeesData, EmployeesBatchData } from '../../services/employeesService';
import { useNavigate } from 'react-router-dom';
import { FormControl } from '@mui/material';
import { handleInputValueChange } from '../../utils/SelectJobs';
import HeaderSurveys from '../../components/HeaderSurveys';

type Props = {};

interface Employee {
  firstName: string;
  lastName: string;
  idCard: string;
  phoneNumber: string;
  email: string;
  role: string;
  jobDescription?: string;
  jobCode?: number;
}

const roles = [
  { key: "system administrator", value: "Responsable del sistema" },
  { key: "legal representative or employer", value: "Representante legal o empleador" },
  { key: "worker", value: "Trabajador" }
]

const FormEmployees = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [jobOptions, setJobOptions] = useState<{ value: string; label: string }[]>([]);

  const [newEmployee, setNewEmployee] = useState<Employee>({
    firstName: '',
    lastName: '',
    idCard: '',
    phoneNumber: '',
    email: '',
    role: '',
    jobDescription: '',
    jobCode: 0,
  });

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value, checked, type } = e.target;
    if (index != null) {
      const updatedEmployees = employees.map((emp, empIndex) =>
        empIndex === index ? { ...emp, [name]: type === 'checkbox' ? checked : value } : emp
      );
      setEmployees(updatedEmployees);
    } else {
      setNewEmployee({
        ...newEmployee,
        [name]: type === 'checkbox' ? checked : value
      });
    }
  };

  const handleChangeRole = (index: number, role: string) => {
    const newEmployees = [...employees];
    const employee = newEmployees[index];
    employee.role = role;
    setEmployees(newEmployees);
  };

  const handleChangeJobType = (index: number, jobDescription: string, jobCode: number | undefined) => {
    const newEmployees = [...employees];
    newEmployees[index] = { ...newEmployees[index], jobDescription, jobCode };
    setEmployees(newEmployees);
  };

  const addEmployee = () => {
    setEmployees([...employees, newEmployee]);
    setNewEmployee({
      firstName: '',
      lastName: '',
      idCard: '',
      phoneNumber: '',
      email: '',
      role: '',
      jobDescription: '',
      jobCode: 0,
    });
  };

  const removeEmployee = (index: number) => {
    const updatedEmployees = employees.filter((_, empIndex) => empIndex !== index);
    setEmployees(updatedEmployees);
  };

  const handleClick = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const employeesData = employees.map(
      emp => new EmployeesData(
        emp.firstName, emp.lastName, emp.idCard, emp.phoneNumber, emp.email, emp.role, emp.jobDescription, emp.jobCode
      )
    );

    employeesService.registerEmployee(
      new EmployeesBatchData(employeesData),
      response => {
        navigate('/evidence')
      },
      err => {
        setErrorMessage('Error en el envío de datos de empleados.');
      },
    );
    setLoading(false);
  };

  const handleJobChange = (index: number, selectedOption: SingleValue<{ value: string; label: string }>) => {
    const jobDescription = selectedOption ? selectedOption.label : '';
    const jobCode = selectedOption ? parseInt(selectedOption.value, 10) : undefined;
    handleChangeJobType(index, jobDescription, jobCode);
  };

  return (
    <>
      <div className="grid justify-center place-items-start w-full min-h-screen h-auto laptop:border laptop:border-white desktop:border desktop:border-white px-5">
        <div className='w-full'>
            <HeaderSurveys title={`Gestión de Empleados`} />
          <div className='grid w-screen place-items-center text-center items-center place-content-center mt-8 text-xs overflow-auto'>
            {employees[0] &&
              <table className='shadow-xl rounded-xl'>
                <thead className='p-2'>
                  <tr>
                    <th><strong>Nombre</strong></th>
                    <th><strong>Apellido</strong></th>
                    <th><strong>Documento de Identidad</strong></th>
                    <th><strong>Número de Teléfono</strong></th>
                    <th><strong>Correo Electrónico</strong></th>
                    <th><strong>Rol</strong></th>
                    <th><strong>Cargo</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr key={index}>
                      <td>
                        <input className='border-2 my-3 h-6 border-black mx-1' name='firstName' type="text" value={employee.firstName} onChange={e => handleInputChange(e, index)} />
                      </td>
                      <td>
                        <input className='border-2 h-6 border-black mx-1' name='lastName' type="text" value={employee.lastName} onChange={e => handleInputChange(e, index)} />
                      </td>
                      <td>
                        <input className='border-2 h-6 border-black mx-1' name='idCard' type="number" value={employee.idCard} onChange={e => handleInputChange(e, index)} />
                      </td>
                      <td>
                        <input className='border-2 h-6 border-black mx-1' name='phoneNumber' type="number" value={employee.phoneNumber} onChange={e => handleInputChange(e, index)} />
                      </td>
                      <td>
                        <input className='border-2 h-6 border-black mx-1' name='email' type="email" value={employee.email} onChange={e => handleInputChange(e, index)} />
                      </td>
                      <td>
                        <select
                          className='pl-4 max-h-9 h-6 w-44 hover:bg-gray-100'
                          title='Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales'
                          value={employee.role || ''}
                          onChange={(e) => handleChangeRole(index, e.target.value)}
                          key={index}
                        >
                          <option value="">Selecciona...</option>
                          {roles.map((role) => (
                            <option key={role.key} value={role.key}>
                              {role.value}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <FormControl fullWidth  >
                          <Select
                            inputId="job-select"
                            placeholder="Escribe para buscar cargos"
                            name="job_description"
                            options={jobOptions}
                            value={jobOptions.find(option => option.label === employee.jobDescription)}
                            onChange={(selectedOption) => handleJobChange(index, selectedOption)}
                            onInputChange={(value: string) => handleInputValueChange(value, setJobOptions)}
                            noOptionsMessage={() => 'Escribe para buscar cargos'}
                            className='px-3 min-w-56 text-left'
                          />
                        </FormControl>
                      </td>
                      <td>
                        <button className='mt-1 px-2 ml-2 py-1 bg-secondaryPink hover:bg-red-700 text-white' onClick={() => removeEmployee(index)}>Eliminar</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
            <div>
              <button
                className='mr-5 mt-4 bg-primaryGreen text-sm rounded-lg w-auto mobile:min-w-32 px-4 h-8 text-black font-semibold hover:bg-secondaryGreen'
                onClick={addEmployee}
                disabled={loading}
              >Agregar Empleado
              </button>
              {employees[0] &&
                <button
                  className='mt-4 bg-primaryGreen text-sm rounded-lg w-auto mobile:min-w-32 px-4 h-8 text-black font-semibold hover:bg-secondaryGreen'
                  onClick={handleClick}
                >Enviar Registro
                </button>
              }
            </div>
            {(errorMessage) && <span className='text-red-700'>{errorMessage}</span>}
          </div>
        </div>
      </div>
    </>
  )
}

export default FormEmployees;
