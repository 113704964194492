import React, { useState } from 'react';

export interface Option {
  value: number;
  label: string;
  disabled?: boolean;
}

interface MultiSelectInputProps {
  id: string;
  label: string;
  values?: number[]; // Values as numbers
  onChange: (selectedValues: number[]) => void;
  options: Option[];
  title?: string;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({ id, label, values = [], onChange, options, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionChange = (optionValue: number) => {
    const newValues = values.includes(optionValue)
      ? values.filter(value => value !== optionValue)
      : [...values, optionValue];
    onChange(newValues);
  };

  const handleSelectAll = () => {
    if (values.length === options.length) {
      onChange([]); // Deselect all
    } else {
      onChange(options.map(option => option.value)); // Select all
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mb-4 w-full">
      <label htmlFor={id} className="mb-1 text-xl">
        {label}
      </label>
      <div className="border border-gray-300 bg-gray-200 rounded-md">
        <button
          type="button"
          onClick={toggleDropdown}
          className="w-full py-2 px-3 text-sm text-left flex justify-between items-center"
          title={title}
        >
          {values.length > 0 
            ? options.filter(option => values.includes(option.value)).map(option => option.label).join(', ')
            : 'Seleccionar'}
          <svg
            className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            <label className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-200">
              <input
                type="checkbox"
                checked={values.length === options.length}
                onChange={handleSelectAll}
                className="mr-2"
              />
              Seleccionar todos
            </label>
            {options.map((option) => (
              <label key={option.value} className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-200">
                <input
                  type="checkbox"
                  checked={values.includes(option.value)}
                  onChange={() => handleOptionChange(option.value)}
                  disabled={option.disabled}
                  className="mr-2"
                />
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectInput;
