import React from 'react';

interface LabelValueDisplayProps {
  label: string;
  value: string;
}

const LabelValueDisplay: React.FC<LabelValueDisplayProps> = ({ label, value }) => {
  return (
    <div className="flex flex-col mb-4 w-full">
      <div className="flex justify-between items-center gap-4">
        <span className="text-lg font-semibold">{label}</span>
        <span className="text-lg text-gray-700">{value}</span>
      </div>
    </div>
  );
};

export default LabelValueDisplay;
