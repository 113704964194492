import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, ToolbarProps } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import workPlanCalendaIcon from '../assets/icons/work-plan-item-calendar-icon.svg';
import trainingPlanCalendaIcon from '../assets/icons/training-plan-item-calendar.svg';
import moment from 'moment';
import 'moment/locale/es';
import { ImprovementPlansData } from '../services/autoevaluationService';
import DayHeader from './DayHeader';
import rightArrow from '../assets/icons/rigth-arrow-icon.svg'
import leftArrow from '../assets/icons/left-arrow-icon.svg'
import CancelButton from './CancelButton';
moment.locale('es');

type CustomToolbarProps = {
  label: string;
  onNavigate: (date: Date) => void;
  date: Date;
};

const CustomToolbar: React.FC<CustomToolbarProps> = ({ label, onNavigate, date }) => (
  <div className="flex rbc-toolbar items-center justify-center">
    <button
      type="button"
      onClick={() => onNavigate(moment(date).subtract(1, 'month').toDate())} 
      className="rbc-btn rbc-btn-group flex items-center justify-center"
      aria-label="Previous Month"
    >
      <img
        src={leftArrow}
        alt="Flecha izquierda"
        className="w-6 h-6"
      />
    </button>
    <span className="rbc-toolbar-label text-secondaryBrown text-xl">{label}</span>
    <button
      type="button"
      onClick={() => onNavigate(moment(date).add(1, 'month').toDate())} 
      className="rbc-btn rbc-btn-group flex items-center justify-center"
      aria-label="Next Month"
    >
      <img
        src={rightArrow}
        alt="Flecha derecha"
        className="w-6 h-6"
      />
    </button>
  </div>
);

type Props = {
  plans: ImprovementPlansData[];
};

interface Event {
  start: Date;
  end: Date;
  color: string;
  description: string;
}

const convertToEvents = (data: ImprovementPlansData[]): Event[] => {
  return data.map(item => {
    let color = '#FFFFFF';

    if (item.is_training_plan) {
      color = '#1EC33A';
    } else if (item.is_work_plan) {
      color = '#F54C2F';
    }

    return {
      start: item.start_date ? new Date(item.start_date) : new Date(),
      end: item.end_date ? new Date(item.end_date) : new Date(),
      color,
      description: item.activity || 'Sin descripción',
    };
  });
};

const MyCalendar: React.FC<Props> = ({ plans }) => {
  const localizer = momentLocalizer(moment);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null); 

  const eventPropGetter = (event: Event) => {
    const backgroundColor = event.color;
    return {
      style: {
        backgroundColor,
        height: '4px', 
      },
      className: 'clickable-event', 
    };
  };
  

  const handleNavigate = (date: Date) => {
    setCurrentDate(date);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null); // Cierra el modal
  };

  useEffect(() => {
    setEvents(convertToEvents(plans));
  }, [plans]);

  return (
    <div className="mt-5 ">
      <div className="flex flex-col w-full text-start">
        <div className="no-border">
          <Calendar
            localizer={localizer}
            events={events}
            view='month'
            date={currentDate}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 420, width: 540 }}
            components={{
              toolbar: (props: ToolbarProps) => (
                <CustomToolbar
                  label={moment(currentDate).format('MMMM YYYY')}
                  onNavigate={handleNavigate}
                  date={currentDate}
                />
              ),
              header: ({ date }) => <DayHeader date={date} /> 
            }}
            eventPropGetter={eventPropGetter}
            views={['month']}
            messages={{
              date: 'Fecha',
              time: 'Hora',
              event: 'Evento',
              allDay: 'Todo el día',
              week: 'Semana',
              work_week: 'Semana laboral',
              day: 'Día',
              month: 'Mes',
              previous: 'Anterior',
              next: 'Siguiente',
              yesterday: 'Ayer',
              tomorrow: 'Mañana',
              today: 'Hoy',
              noEventsInRange: 'No hay eventos en este rango',
              showMore: () => "+ "
            }}
            onSelectEvent={setSelectedEvent}
          />
        </div>
        <div className="flex justify-around items-center w-full mt-2 px-4 text-primaryGray">
          <span className="flex text-sm gap-3">
            <img src={workPlanCalendaIcon} alt="Guion rojo" />
            Plan de trabajo
          </span>
          <span className="flex text-sm gap-3">
            <img src={trainingPlanCalendaIcon} alt="Guion verde" />
            Plan de capacitación
          </span>
        </div>
      </div>

      {selectedEvent && (
        <div className="fixed text-secondaryBrown inset-0 flex text-center items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded shadow-lg max-w-md mx-auto p-8">
          <div className='grid justify-start text-start gap-3'>
          <p><strong>Actividad:</strong> {selectedEvent.description}</p>
            <p><strong>Fecha de inicio:</strong> {selectedEvent.start.toLocaleDateString()}</p>
            <p><strong>Fecha de fin:</strong> {selectedEvent.end.toLocaleDateString()}</p>
            
          </div>
          <div className='mt-5'>
          <CancelButton
              onClick={handleCloseModal}
              text='Cerrar'
            
            />
          </div>
          
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
