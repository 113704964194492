/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { companyService, CompanyData } from '../../services/companyServices';
import { daneService, Data } from '../../services/daneService';
import jsonDataRisk from '../../utils/riskClass.json'
import SuccessModal from '../../components/SuccessModal';
import Button from '../../components/Button';
import TextFieldString from '../../components/TextFieldString';
import HeaderSurveys from '../../components/HeaderSurveys';
import { mainDivClassName } from '../../utils/MainDivClassName';
import SelectInput from '../../components/SelectInput';
import SelectInputNumber from '../../components/SelectInputNumber';
import TextFieldNumber from '../../components/TextFieldNumber';


const risks = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 }
]

const arls = [
  { label: "SURA", value: "SURA" },
  { label: "POSITIVA", value: "POSITIVA" },
  { label: "AXXA COLPATRIA", value: "AXXA COLPATRIA" },
  { label: "COLMENA", value: "COLMENA" },
  { label: "BOLIVAR", value: "BOLIVAR" },
  { label: "LA EQUIDAD", value: "LA EQUIDAD" }
]

interface CodesFromRisk {
  value: string;
  label: string;
}

const FormCompany = () => {
  const [name, setName] = useState<string>('');
  const [nit, setNit] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [arl, setArl] = useState<string>('');
  const [activityCode, setActivityCode] = useState<string>('0');
  const [codes, setCodes] = useState<CodesFromRisk[]>([]);
  const [selectedState, setSelectedState] = useState<string>('')
  const [numberOfEmployes, setNumberOfEmployes] = useState<number>(0);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<number>(0)
  const [cities, setCities] = useState<Data[]>([])
  const [data, setData] = useState<Data[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChangeRisk = (value: number) => {
    setSelectedRiskLevel(value);

    const codesFromRisk = jsonDataRisk.filter
      (item => item.CLASE_DE_RIESGO === value).map(item => ({
        value: item.CODIGO_CIIU,
        label: `${item.CODIGO_CIIU} - ${(item.ACTIVIDAD_ECONOMICA)?.toLowerCase()}`
      }));

    setCodes(codesFromRisk)
  };
  const handleChangeArl = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setArl(value);

  };

  const handleChangeActivity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

      setActivityCode(value);
    }
  

  const handleChangeDepartament = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const departmentCode = event.target.value;
    setSelectedState(departmentCode);

    const citiesFromDepartment = data
      .filter(item => item.c_digo_dane_del_departamento === departmentCode);

    setCities(citiesFromDepartment);
  };

  const handleChangeCity = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCity(event.target.value);
  }

  useEffect(() => {
    daneService.listDaneData(
      response => {
        setData(response)
      },
      err => {

      });
  }, [])

  const handleClick = () => {
    setLoading(true);
    const emailUser = localStorage.getItem("email");

    if (emailUser == null) return
    companyService.register(
      new CompanyData(
        name, nit, phone, city, address, selectedState, activityCode, numberOfEmployes, selectedRiskLevel.toString(), emailUser, arl),
      response => {
        setLoading(false);
        setShowModal(true);
      },
      err => {
        setLoading(false)
        setErrorMessage('Error en el envio de datos, verifica que todos los datos están correctos');
      });

    setLoading(false);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const sortedCities = [...cities].sort((a, b) => a.municipio.localeCompare(b.municipio));
  const sortedDepartments = [...data]
    .reduce<Data[]>((unique, item) => {
      if (!unique.some(dept => dept.c_digo_dane_del_departamento === item.c_digo_dane_del_departamento)) {
        unique.push(item);
      }
      return unique;
    }, [])
    .sort((a, b) => a.departamento.localeCompare(b.departamento));
  return (
    <>
      {showModal && (
        <SuccessModal
          message="Información enviada con éxito"
          buttonText="Continuar"
          redirectPath="/formEmployees"
          onClose={handleCloseModal}
        />
      )}
      
      <div className={`grid items-center  h-screen mobile:h-auto  ${mainDivClassName}  p-2`}>
     <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-2 border-secondaryBrown place-content-center">
       <HeaderSurveys title="Configuración inicial" />
       <div className='grid grid-cols-2 mobile:grid-cols-1 p-4 gap-4 items-end text-secondaryBrown'>
          <TextFieldString
            label="Razón Social"
            value={name}
            onChange={(e) => setName(e.target.value)}
            title='Nombre legal de tu empresa como está registrado oficialmente'
          />
          <TextFieldString
            label="NIT"
            value={nit}
            onChange={(e) => setNit(e.target.value)}
            title='Número de Identificación Tributaria de tu empresa'
          />
          <SelectInput
            label="ARL"
            id='arl-company'
            value={arl}
            onChange={handleChangeArl}
            options={arls}
            title='Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales'
          />
          <TextFieldString
            label="Número de teléfono"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div className="w-full flex flex-col mb-4">
            <label className="mb-1 text-xl ">Departamento</label>
            <select
              className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
              id="department"
              value={selectedState}
              onChange={handleChangeDepartament}
            >
              <option value="">Selecciona...</option>
              {sortedDepartments.map((item, index) => (
                <option key={index} value={item.c_digo_dane_del_departamento}>
                  {item.departamento}
                </option>
              ))}
            </select>
          </div>
          <TextFieldString
            label="Dirección"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            title='Proporciona la dirección completa de la ubicación principal de tu empresa'
          />
            <div className='w-full flex flex-col mb-4'>
              <label className='mb-1 text-xl' >Ciudad : </label>
              <select
                className='"border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full'
                id="city"
                value={city}
                onChange={handleChangeCity}>
                <option >Selecciona...</option>

                {sortedCities.map((city, index) => (
                  <option key={index} value={city.c_digo_dane_del_municipio}>
                    {city.municipio}
                  </option>
                ))}

              </select>
            </div>
            <SelectInputNumber
            label="Clase de riesgo "
            id='risk-company'
            value={selectedRiskLevel}
            onChange={handleChangeRisk}
            options={risks}
            title='Selecciona la clase de riesgo asociada a las actividades principales de tu empresa, de acuerdo con la tabla de clasificación de riesgos laborales'
          />
          <TextFieldNumber
            label="Número de empleados"
            value={numberOfEmployes}
            onChange={(e) => setNumberOfEmployes(e.target.valueAsNumber)}
            title='Total de empleados que trabajan en tu empresa'
          />
          <div className='w-full flex flex-col mb-4'>
            <label className='mb-1 text-xl text-center'>
              Código CIIU actividad económica principal de la empresa:
            </label>
            
            <select
              className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
              value={activityCode}
              onChange={handleChangeActivity}
            >
              <option value="">Selecciona...</option>
              {codes.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>


          

       </div>
       <span className='animate-pulse text-center text-red-600 text-sm '>
            {errorMessage && <div>{errorMessage}</div>}
          </span>
          <div className="flex justify-center my-4">
            <Button
              text='Enviar formulario'
              onClick={handleClick}
              disabled={loading}
              
              />
          </div>

         
        </div>
      </div>
    </>
  )
}
export default FormCompany
