import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { companyService } from './services/companyServices';
import { useAppDispatch } from './app/hooks';
import { setLoggedIn } from './features/userSlice';
import LoginForm from './views/login/LoginForm';
import Home from './views/home/Home';
import Absenteeism from './views/absenteeism/Absenteeism';
import FormCompany from './views/company/FormCompany';
import UpdatePassword from './views/updatePassword/UpdatePassword';
import RegisterCompany from './views/company/RegisterCompany';
import WompiView from './views/wompi/WompiView';
import PaymentResultView from './views/wompi/PaymentResultView';
import Plans from './views/plans/Plans';
import FormEmployees from './views/company/FormEmployees';
import { TabsCustomAnimation } from './views/company/FinalRegistration';
import { Autoevaluation } from './views/company/Autoevaluation'
import WorkPlan from './views/workPlan/WorkPlan';
import TrainingPlan from './views/trainingPlan/TrainingPlan';
import FormSociodemographicMatriz from './views/company/FormSociodemographicMatriz';
import FormHealthConditions from './views/company/FormHealthConditions';
import FormWorkConditions from './views/company/FormWorkConditions';
import StartPayment from './views/wompi/StartPayment';
import ModuleEmployees from './views/employees/ModuleEmployees';
import PrivateRoute from './components/PrivateRoute';

import './App.css';


const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    companyService.getMyCompany(
      () => { },
      () => {
        dispatch(setLoggedIn(false));
        localStorage.clear();
      }
    )
  }, [dispatch]);
  return <Router>
    <Routes>
      <Route path="/" element={<PrivateRoute>
        <Home />
      </PrivateRoute>} />
      <Route path="/login" element={<LoginForm />} />
      <Route path='/home' element={<PrivateRoute>
        <Home />
      </PrivateRoute>} />
      <Route path='/absenteeism' element={<PrivateRoute>
        <Absenteeism />
      </PrivateRoute>} />
      <Route path='/configuracion-inicial' element={<PrivateRoute>
        <FormCompany />
      </PrivateRoute>} />
      <Route path='/formEmployees' element={<PrivateRoute>
        <FormEmployees />
      </PrivateRoute>} />
      <Route path='/socioDemo/:token' element={<FormSociodemographicMatriz />} />
      <Route path='/user/new-password/:token' element={<UpdatePassword />} />
      <Route path='/plans/:planId' element={<WompiView />} />
      <Route path='/start-payment/:token' element={<StartPayment />} />
      <Route path='/payment-result' element={<PaymentResultView />} />
      <Route path='/registerCompany/:token' element={<RegisterCompany />} />
      <Route path='/plans' element={<Plans />} />
      <Route path='/evidence' element={<PrivateRoute>
        <TabsCustomAnimation />
      </PrivateRoute>} />
      <Route path='/autoevaluation' element={<PrivateRoute>
        <Autoevaluation />
      </PrivateRoute>} />
      <Route path='/work-plan' element={<PrivateRoute>
        <WorkPlan />
      </PrivateRoute>} />
      <Route path='/training-plan' element={<PrivateRoute>
        <TrainingPlan />
      </PrivateRoute>} />
      <Route path='/socioDemo/:token' element={<FormSociodemographicMatriz />} />
      <Route path='/healthConditions/:token' element={<FormHealthConditions />} />
      <Route path='/workConditions/:token' element={<FormWorkConditions />} />
      <Route path='/employees' element={<PrivateRoute>
        <ModuleEmployees />
      </PrivateRoute>} />
    </Routes>
  </Router>
};

export default App;
