import React from 'react';

const DayHeader: React.FC<{ date: Date }> = ({ date }) => {
  const dayNames = ['D', 'L', 'M', 'X', 'J', 'V', 'S']; 
  const dayIndex = date.getDay(); 
  
  return (
    <div className="text-center text-primaryRed">
      {dayNames[dayIndex]}
    </div>
  );
};

export default DayHeader;