import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initializeState: () => any = () => {
    const accessToken = localStorage.getItem('access_token');
    const userType = localStorage.getItem('user_type');
    if (accessToken) {
        return {
            isLoggedIn: accessToken !== null && accessToken !== undefined,
            userType: userType !== null && userType !== undefined,
        };
    }
    return {
        isLoggedIn: false,
    };
};

const userSlice = createSlice({
    name: 'user',
    initialState: initializeState,
    reducers: {
        setLoggedIn(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload;
        },
        setUserType(state, action: PayloadAction<string>) {
            state.userType = action.payload;
        },
    }
});

export const { setLoggedIn, setUserType } = userSlice.actions;
export default userSlice.reducer;
