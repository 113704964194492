import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface EmployeeResponse {
  message: string;
}

export class EmployeesData {
  first_name: string;
  last_name: string;
  id_card: string;
  phone: string;
  email: string;
  role: string;
  job_description?: string;
  job_code?: number;

  constructor(
    first_name: string,
    last_name: string,
    id_card: string,
    phone: string,
    email: string,
    role: string,
    job_description?: string,
    job_code?: number

  ) {
    this.first_name = first_name;
    this.last_name = last_name;
    this.id_card = id_card;
    this.phone = phone;
    this.email = email;
    this.role = role;
    this.job_description = job_description;
    this.job_code = job_code;
  }
}

export class EmployeesBatchData {
  employees: EmployeesData[];

  constructor(employees: EmployeesData[]) {
    this.employees = employees;
  }
}

type OnSuccess = (response: EmployeeResponse) => void;

type OnError = (error: any) => void;

const registerEmployee = (employeesBatchData: EmployeesBatchData, onSuccess: OnSuccess, onError: OnError): void => {
  axios.post<EmployeeResponse>(`${API_URL}/companies/auth/register/employees`, JSON.stringify(employeesBatchData),
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => onSuccess(response.data)
    )
    .catch(error => onError(error)
    )
};

const updateEmployee = (
  id: number, // ID del empleado
  data: any, // Objeto con los datos del empleado a actualizar
  onSuccess: OnSuccess,
  onError: OnError
): void => {
  axios.put<EmployeeResponse>(
    `${API_URL}/companies/auth/update/employee/${id}`,
    data, 
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'Content-Type': 'application/json'
      }
    }
  )
  .then(response => onSuccess(response.data))
  .catch(error => onError(error));
};

export interface EmployeesListData {
  id: number;
  first_name: string;
  last_name: string;
  id_card: string;
  phone: string;
  email: string;
  role: string;
  role_es: string;
  status: string;
  job_description: string;
  job_code:number;
  company: number;
}
interface responseEmployeesData {
  results: EmployeesListData[];
  count : number;
  next: string;
  previous: string;
}

const employeesData = (
  onSuccess: (response: responseEmployeesData) => void,
  onError: (error: any) => void,
  filter?: string,  
  url?: string
): void => {
  let requestUrl = url || `${API_URL}/companies/auth/employees?limit=10&offset=0`;
  
  if (filter) {
    requestUrl += `&filter=${filter}`;
  }

  axios.get<responseEmployeesData>(requestUrl, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json'
      }
  })
  .then(response => onSuccess(response.data))
  .catch(error => onError(error));
};

export const employeesService = {
  registerEmployee,
  updateEmployee,
  employeesData,
};