import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { userUpdatePasswordService, NewPasswordData } from '../../services/userUpdatePassword';
import SuccessModal from '../../components/SuccessModal';
import Button from '../../components/Button';
import TextFieldString from '../../components/TextFieldString';
import HeaderSurveys from '../../components/HeaderSurveys';
import { mainDivClassName } from '../../utils/MainDivClassName';

type Props = {
}

const NewPassword = (props: Props) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setconfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const { token } = useParams<{ token?: string }>();

  const handleClick = () => {
    setLoading(true);

    userUpdatePasswordService.createNewPassword(new NewPasswordData(token, password, confirmPassword),
      response => {
        setLoading(false);
        setShowModal(true);
      },
      err => {
        setLoading(false);
        if (!token || password !== confirmPassword) {
          setErrorMessage('Error al Crear la contraseña. Por favor, verifica que sean iguales.');
        } else {
          setErrorMessage("Su token no es válido o ha expirado.")
        }
      });

    setLoading(false);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <SuccessModal
          message="Contraseña creada con éxito"
          buttonText="Ir al login"
          redirectPath="/login"
          onClose={handleCloseModal}
        />
      )}
      
      <div className={"mobile:p-2 flex h-screen justify-center items-center text-secondaryBrown " + (mainDivClassName)} >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-secondaryBrown border-2">
        <HeaderSurveys title="Crea tu contraseña" />
        <div className="flex flex-col justify-center items-center p-4">

          <TextFieldString
            label="Contraseña"
            value={password}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextFieldString
            label="Confirma la contraseña"
            value={confirmPassword}
            type='password'
            onChange={(e) => setconfirmPassword(e.target.value)}
          />

          <span className="col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm">
            {errorMessage && <div>{errorMessage}</div>}
          </span>
        </div>
        <div className='flex justify-center gap-7 pb-4'>
          <Button
            text="Crear Contraseña"
            onClick={handleClick}
            disabled={loading}
          />
          
        </div>
      </div>
    </div>
    </>
  )
}
export default NewPassword

