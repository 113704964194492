import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

export interface Plan {
    id: number;
    name: string;
    description: string;
    price: number;
}

type OnSuccessList = (data: Plan[]) => void;
type OnSuccessDetail = (data: Plan) => void;
type OnError = (error: any) => void;

const listPlans = (onSuccess: OnSuccessList, onError: OnError): void => {
    axios.get<Plan[]>(`${API_URL}/plans/plans`)
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

const getPlan = (planId: number, onSuccess: OnSuccessDetail, onError: OnError): void => {
    axios.get<Plan>(`${API_URL}/plans/plans/${planId}`)
        .then(response => onSuccess(response.data))
        .catch(error => onError(error));
};

export const plansService = {
    listPlans,
    getPlan,
};
