// En Absenteeism.tsx

import { useEffect, useState } from 'react';
import CreateAbsenteeismModal, { CreateAbsenteeismFormData } from '../../components/absenteeism/CreateAbsenteeismModal';
import { AbsenceData, AbsenceResponse, AbsenteeismService } from '../../services/absenteeismService';
import ConfirmationModal from '../../components/ConfirmationModal';
import MainLayout from '../../components/MainLayout';
import TextFieldDate from '../../components/TextFieldDate';
import TableAbsenteeism, { Column } from '../../components/TableAbsenteeism';
import SelectInput from '../../components/SelectInput';
import { absenceTypes } from '../../utils/AbsenteeismUtils';

const columns: Column[] = [
  { id: 'employee_data', label: 'Nombre' },
  { id: 'absense_type', label: 'Tipo' },
  { id: 'description', label: 'Descripción' },
  { id: 'start_date', label: 'Fecha Inicio' },
  { id: 'end_date', label: 'Fecha Final' },
  { id: 'evidence_url', label: 'Evidencia' }
];

const Absenteeism = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());;
  const [endDate, setEndDate] = useState<Date | null>(new Date());;
  const [absenceType, setAbsenceType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [absences, setAbsences] = useState<AbsenceResponse[]>([]);

  useEffect(() => {
    setEndDate(null)
    setStartDate(null)
    fetchAbsences();
  }, []);

  const fetchAbsences = () => {
    if (!startDate || !endDate) {
      console.error('Both start date and end date are required.');
      return;
    }

    AbsenteeismService.getAbsences(
      startDate,
      endDate,
      absenceType,
      (response) => {
        setAbsences(response);
      },
      (error) => {
        console.error('Error al obtener las ausencias:', error);
      }
    );
  };

  const handleFilter = () => {
    fetchAbsences();
  };


  const handleCreateAbsence = (formData: CreateAbsenteeismFormData) => {

    const absenceData = new AbsenceData(
      formData.absenceType,
      formData.description,
      formData.startDate ,
      formData.endDate ,
      formData.evidenceUrl,
      formData.employee
    );

    AbsenteeismService.createAbsence(
      absenceData,
      (response) => {
        console.log('Ausencia creada:', response);
        setShowConfirmation(true);
        setOpenModal(false);

      },
      (error) => {
        console.error('Error al crear la ausencia:', error);

      }
    );
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <MainLayout>
        <div className='grid  text-center items-center text-secondaryBrown'>
          <h1 className="text-4xl text-start font-bold mb-16 text-primaryOrange">Ausentismo</h1>
          <div className='grid place-content-start mb-16' >
            <h2 className='text-xxl text-start mb-4 text-primaryOrange'>Nuevo ausentismo</h2>
            <button
              onClick={() => setOpenModal(true)}
              className=" bg-primaryGreen py-2 px-4  text-black font-semibold rounded-lg  hover:bg-secondaryGreen"
            >
              Crear ausentismo
            </button>
          </div>
          <h2 className='text-xxl text-start mb-4 text-primaryOrange'>Histórico de ausentismo</h2>
          <div className="flex gap-6 items-center text-start mb-10 mx-4">
            <TextFieldDate
              label="Fecha Inicio"
              value={startDate}
              onChange={(e) => setStartDate(e.target.valueAsDate)}
            />
            <TextFieldDate
              label="Fecha fin"
              value={endDate}
              onChange={(e) => setEndDate(e.target.valueAsDate)}
            />
            <SelectInput
              id="absence-type"
              label="Tipo de Ausentismo"
              value={absenceType}
              onChange={(e) => setAbsenceType(e.target.value)}
              options={absenceTypes.map(absenceType => ({ value: absenceType, label: absenceType }))}
            />
            <button
              className="bg-primaryGreen px-4  w-36 h-8 text-black font-semibold rounded-md  hover:bg-secondaryGreen"
              onClick={handleFilter}>
              Filtrar
            </button>
          </div>
          <TableAbsenteeism
            columns={columns}
            rows={absences}
          />

          <CreateAbsenteeismModal 
            open={openModal} 
            onClose={() => { 
              console.log('Closing modal'); 
              console.log(openModal);
              
              setOpenModal(false);
            }} 
            onCreate={handleCreateAbsence} 
          />
          <ConfirmationModal open={showConfirmation} onClose={handleCloseConfirmation} />

        </div>

      </MainLayout>


    </>
  );
};

export default Absenteeism;
