import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

interface Column {
  id: string;
  label: string;
  format?: (value: any) => any;
}

interface Row {
  [key: string]: any;
}

interface TableItemsExtrasProps {
  title: string;
  columns: Column[];
  rows: Row[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleOpenModal: (itemName: string) => void; 
  evidenceIcon: string;
}

const TableItemsExtras: React.FC<TableItemsExtrasProps> = ({
  title,
  columns,
  rows,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenModal,
  evidenceIcon,
}) => {
  return (
    <div className="overflow-x-auto">
       <div className="flex items-center mb-2">
          <div className="text-xl font-semibold text-secondaryOrange">{title}</div>
          <div className="ml-2 relative flex items-center group">
            <FaInfoCircle className="text-secondaryOrange cursor-pointer" />
            <div className="absolute top-full left-0 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-primaryGray text-white text-xs rounded p-2 z-50 w-64">
            ítems relacionados con batería de riesgo psicosocial, programas de bienestar, estudio de puestos de trabajo, entre otros.
            </div>
          </div>
          </div>
      <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
        <thead>
          <tr className="w-full">
            {columns.map((column) => (
              <th key={column.id} className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-secondaryBrown text-center'>
          {rows.length > 0 ? (
            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <td key={column.id} className="px-6 py-3 text-sm">
                      {column.id === 'evidences' ? (
                        <button onClick={() => handleOpenModal(row.item)}>
                          <img src={evidenceIcon} alt="Logo evidencias" />
                        </button>
                      ) : (
                        column.format && typeof value === 'number'
                          ? column.format(value)
                          : value
                      )}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-4">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex justify-between items-center py-2">

        <button
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
          className="p-2 border border-gray-300 rounded bg-gray-200"
        >
          Anterior
        </button>
        <div >
          <label className='mr-2 text-primaryBrown'>Numero de filas</label>
          <select value={rowsPerPage} onChange={handleChangeRowsPerPage} className="p-2 border border-gray-300 rounded">
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>

        </div>

        <button
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
          className="p-2 border border-gray-300 rounded bg-gray-200"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default TableItemsExtras;
