import React from 'react';
import logoLaborus from '../../assets/icons/logoLaborus.svg'
import avatarDefault from '../../assets/icons/avatar-default-icon.svg'
import Notifications from '../Notifications';
import UserMenu from '../UserMenu';

type Props = {};

const Header = (props: Props) => {

  return (
    <div>
      <header className="flex items-center justify-between p-4 mx-2 z-30 bg-white h-32 fixed top-0 left-32 right-0 mobile:left-0">
        <div className="flex items-center gap-4 flex-shrink-0">
          <img alt="Logo Laborus" src={logoLaborus} className="h-16" />
        </div>
        <div className='flex-grow flex items-center justify-end gap-10'>
          <div className="flex items-center gap-4">
            <img className="min-w-[96px] min-h-[96px]" src={avatarDefault} alt="avatar" />
            <div className='flex-col text-xxl text-start text-secondaryOrange'>
            <p className='mb-2'>
              <strong>BIENVENIDO</strong>
            </p>
            <p>
              {localStorage.getItem("company_name") ?? ''}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-6 mobile:gap-2">
          <div className="mobile:flex hidden">
            <UserMenu />
          </div>
          <Notifications />
        </div>
      </div>
      </header>
    </div>
  );
};

export default Header;
