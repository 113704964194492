import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || ''; 

interface UserResponsePassword {
    token: string;
    user: any;
}

export class NewPasswordData {
    email:string;
  constructor(
        email:string,
    ) {
    this.email = email;
  }
}

type OnSuccess = (response: UserResponsePassword) => void;

type OnError = (error: any) => void;

const RegisterNewPassword = (newPasswordData: NewPasswordData, onSuccess: OnSuccess, onError: OnError): void => {
   axios.post<UserResponsePassword>(`${API_URL}/api/auth/password/register`, newPasswordData)
    .then(response => onSuccess(response.data)
      )
    .catch(error => onError(error)
    )
};

export const userRegisterPasswordService = {
    RegisterNewPassword,
};