import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { EvidenceRequest, EvidenceResponse, UploadRequest, WorkEvidenceData, uploadImageService } from "../../services/uploadImageServices";
import SuccessModal from "../SuccessModal";

interface AddImagesProps {
  employeeId: number;
}

const AddImages = (props: AddImagesProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {


    return new Promise<EvidenceResponse>((resolve, reject) => {
      uploadImageService.generateUrl(request, (response: EvidenceResponse) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      },
      );
    });
  };

  const uploadAndRegisterImagePromise = (file: File, url: string, finalUrl: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await uploadImageService.uploadImage(new UploadRequest(file, url),
          response => {
            console.log('Upload success:', response);
            uploadImageService.workEvidence(new WorkEvidenceData(props.employeeId, finalUrl),
              success => {
                console.log('WorkEvidence success:', success);
                resolve(success);
              }, reject);
          }, reject);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleClick = async (event: React.FormEvent) => {
    setLoading(true);
    setErrorMessage('');

    try {
      for (const file of files) {
        const response = await generateUrlPromise(new EvidenceRequest(file.name, props.employeeId));
        console.log('Generated URL:', response);
        await uploadAndRegisterImagePromise(file, response.url, response.final_url);
      }
      setSuccessMessage('Imagen subida exitosamente.');
      setShowModal(true);
    } catch (err) {
      console.error('Error during file processing:', err);
      setErrorMessage('Error en el envío de Datos de empleados.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <div className="flex justify-center text-center gap-5 items-center">
        <div>
          <div className="font-semibold bg-secundaryYellow hover:bg-primaryYellow p-3 text-center text-black rounded-md cursor-pointer" {...getRootProps()} >
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <p>Añadir imágenes</p>}
          </div>
          <div className="grid grid-cols-3 place-content-center place-items-center w-max ">
            {files.map((file, index) => (
              <img key={index} src={URL.createObjectURL(file)} alt={`preview-${index}`} style={{ width: '150px', height: '150px' }} />
            ))}
          </div>

        </div>
        <div className="font-semibold border-1 bg-primaryGreen hover:bg-secondaryGreen text-black py-2 px-6 rounded-md">
          <button onClick={handleClick}>Enviar</button>
        </div>
      </div>
      {loading && <p className="text-center">Cargando...</p>}
      {errorMessage && <p>Error: {errorMessage}</p>}
      {showModal && (
        <SuccessModal
          message={successMessage}
          buttonText="Continuar"
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default AddImages;