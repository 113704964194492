
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

interface SuccessModalProps {
  message: string;
  buttonText?: string;
  redirectPath?: string;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, buttonText, redirectPath, onClose  }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (redirectPath) {
      navigate(redirectPath);
    } else {
      onClose();
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-md text-center text-secondaryBrown">
          <h2 className="text-xl mb-4">{message}</h2>
          {buttonText && (
            <Button
              text={buttonText}
              onClick={handleClose}
            />

          )}
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
