import React from 'react';

export interface AbsenceResponse {
  id: number;
  absense_type: string;
  description: string | null;
  start_date: string;
  end_date: string;
  evidence_url: string;
  employee_data: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export interface Column {
  id: keyof AbsenceResponse | 'employee_data';
  label: string;
}

interface TableProps {
  columns: Column[];
  rows: AbsenceResponse[];
}

const Table: React.FC<TableProps> = ({ columns, rows }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    if (newPage >= 0 && newPage < Math.ceil(rows.length / rowsPerPage)) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
        <thead>
          <tr className="w-full">
            {columns.map((column) => (
              <th key={column.id} className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'>
                <span >{column.label}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-secondaryBrown text-center'>
          {rows.length > 0 ? (
            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <tr key={row.id} className="hover:bg-gray-50">
                {columns.map((column) => {
                  let value: string | number | React.ReactNode = '';

                  if (column.id === 'employee_data') {
                    const employeeData = row.employee_data;
                    value = `${employeeData.first_name} ${employeeData.last_name}`;
                  } else {
                    value = row[column.id];
                  }

                  return (
                    <td key={column.id} className="px-6 py-3 text-sm">
                      {column.id === 'evidence_url' ? (
                        <a
                          className='text-secondaryOrange '
                          href={value as string}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ver Evidencia
                        </a>
                      ) : (
                        value !== null ? value.toString() : 'N/A'
                      )}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-4">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex justify-between items-center py-2">
        <button
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
          className="p-2 border border-gray-300 rounded bg-gray-200"
        >
          Anterior
        </button>
        <div>
          <label className='mr-2 text-primaryBrown'>Número de filas</label>
          <select value={rowsPerPage} onChange={handleChangeRowsPerPage} className="p-2 border border-gray-300 rounded">
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <button
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
          className="p-2 border border-gray-300 rounded bg-gray-200"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Table;
