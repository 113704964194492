import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NewUserData, newUserService } from '../../services/newUserService';
import Button from '../../components/Button';
import HeaderSurveys from '../../components/HeaderSurveys';
import TextFieldString from '../../components/TextFieldString';
import { mainDivClassName } from '../../utils/MainDivClassName';


const RegisterCompany = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { token } = useParams<{ token?: string }>();


  const handleClick = () => {
    if (confirmPassword === password) {
      if (token === undefined) return

      setLoading(true);
      newUserService.createNewUser(new NewUserData(email, password, 'company', token),
        () => navigate("/login"),
        () => {
          setLoading(false);
          setErrorMessage('Error al registrar la contraseña. Por favor, verifica los datos.');
        });
    } else {
      setLoading(false);
      setErrorMessage("Las contraseñas deben ser iguales.")
    }
  }
  return (
    <>
      
      <div className={"mobile:p-2 flex h-screen justify-center items-center text-secondaryBrown " + (mainDivClassName)} >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-secondaryBrown border-2">
        <HeaderSurveys title="Registra tu empresa" />
        <div className="flex flex-col justify-center items-center p-4">
          <TextFieldString
            label="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextFieldString
            label="Contraseña"
            value={password}
            type='password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextFieldString
            label="Confirma la contraseña"
            value={confirmPassword}
            type='password'
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <span className="col-span-2 mobile:col-span-1 animate-pulse text-center text-red-600 text-sm">
            {errorMessage && <div>{errorMessage}</div>}
          </span>
        </div>
        <div className='flex justify-center gap-7 pb-4'>
          <Button
            text="Enviar Registro"
            onClick={handleClick}
            disabled={loading}
          />
          
        </div>
      </div>
    </div>
    </>
  )
}
export default RegisterCompany

