import { useEffect, useState } from 'react';
import { beehiveImg, logoLaborus } from '../../assets/icons';
import { authService, LoginCredentials } from '../../services/authServices';
import { userRegisterPasswordService, NewPasswordData } from '../../services/userRegisterPassword';
import { useNavigate } from 'react-router-dom';
import { companyService } from '../../services/companyServices';
import { useAppDispatch } from '../../app/hooks';
import { setLoggedIn, setUserType } from '../../features/userSlice';
import { mainDivClassName } from '../../utils/MainDivClassName';


const LoginForm = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [mostrarLogin, setMostrarLogin] = useState<boolean>(true)
  const [textButtonLogin, setTextButtonLogin] = useState<string>('Entrar');
  const [buttonLogin, setButtonLogin] = useState<boolean>(true);
  const [textButtonPassword, setTextButtonPassword] = useState<string>('Olvidó su contraseña');
  const [ButtonPassword, setButtonPassword] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleLoginAndPasswordRecovery = () => {
    setButtonLogin(!buttonLogin)
    setMostrarLogin(!mostrarLogin)
    setButtonPassword(!ButtonPassword)
    setTextButtonLogin(buttonLogin ? "Enviar correo de actualizacion" : "Entrar")
    setTextButtonPassword(ButtonPassword ? "Volver al login" : "Olvidó su contraseña")
    setErrorMessage(ButtonPassword ? errorMessage : "")
  };

  const handlePasswordKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (mostrarLogin) {
      authService.login(new LoginCredentials(email, password),
        response => {
          const userType = (response as any)["user_type"];
          localStorage.setItem('access_token', (response as any)["access"]);
          localStorage.setItem('email', (response as any)["email"]);
          localStorage.setItem('user_type', userType);
          localStorage.setItem('refresh', (response as any)["refresh"]);
          dispatch(setLoggedIn(true));
          dispatch(setUserType(userType));

          if (userType === 'admin' || userType === 'consultant') {
            navigate("/registerCompany")
          } else if (userType === 'company') {
            companyService.getMyCompany(
              response => {
                if (!response) {
                  navigate("/configuracion-inicial")
                } else {
                  localStorage.setItem('company_name', (response as any)["name"]);
                  navigate(`/home`)
                }
              },
              () => {
                setLoading(false);
                setErrorMessage('Error al validar empresa.');
              }
            )
          }
        },
        () => {
          setLoading(false);
          setErrorMessage('Credenciales incorrectas.');
        });
    } else {
      userRegisterPasswordService.RegisterNewPassword(new NewPasswordData(email),
        () => {
          setErrorMessage('Email para recuperar password enviado.');
          setLoading(false);
        },
        () => {
          setErrorMessage('Error al enviar el correo. Por favor, verifica tus correo.');
          setLoading(false);
        }
      );
    }
  };

  return <div className={"grid h-screen " + (!isSmallScreen && mainDivClassName)}>
    <div className={"flex flex-col h-[42rem] bg-white" + (isSmallScreen ? ' w-full' : ' w-96 border-2 border-secondaryBrown rounded-xl')}>
      <div className={'flex flex-row-reverse flex-none h-1/4 bg-gradient-to-b from-primaryOrange to-white' + (!isSmallScreen && ' rounded-xl')}>
        {isSmallScreen && <img src={beehiveImg} alt='Icono' />}
      </div>
      <div className='flex flex-none h-1/4 justify-center items-start'>
        <img src={logoLaborus} alt='Logo Laborus' />
      </div>
      <div className='flex flex-col space-y-3 grow justify-center items-center px-10 pb-16'>
        <h1 className='text-primaryOrange font-bold text-4xl'>BIENVENIDO</h1>
        <h2 className='text-xl text-primaryBrown'>iniciar sesión</h2>
        <input
          className='pl-4 p-1 border-2 border-secondaryBrown rounded-lg w-full'
          type="text"
          name="email"
          placeholder="Correo electronico"
          onChange={(e) => setEmail(e.target.value)}
        />
        {mostrarLogin &&
          <input
            className='pl-4 p-1 mt-4 border-2 border-secondaryBrown rounded-lg w-full'
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handlePasswordKeyDown}
          />}
        {errorMessage && <span className='animate-pulse text-center text-red-600 text-sm'>{errorMessage}</span>}
        <button className='text-xl text-primaryBrown' onClick={toggleLoginAndPasswordRecovery}>{textButtonPassword}</button>
        <div className="flex justify-center pt-6 ">
          <button
            className={"bg-primaryGreen text-black text-base font-rounded font-bold rounded-lg py-2 hover:bg-secondaryGreen " + (mostrarLogin ? 'px-12' : 'px-4')}
            disabled={loading}
            onClick={handleSubmit}
          >
            {textButtonLogin}
          </button>
        </div>
      </div>
    </div>
  </div>;
};

export default LoginForm;
