
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { standarsItemsTeam1, standarsItemsTeam2, standarsItemsTeam3 } from "../../utils/standarItems";
import ImprovementPlan from "../../components/improvementPlan/ImprovementPlan";
import { AutoevaluationData, autoevaluationService } from "../../services/autoevaluationService";
import { companyService } from "../../services/companyServices";
import SuccessModal from "../../components/SuccessModal";
import { EmployeesListData, employeesService } from "../../services/employeesService";
import { mainDivClassName } from "../../utils/MainDivClassName";
import HeaderSurveys from "../../components/HeaderSurveys";
import SecundaryButton from "../../components/SecundaryButton";
import Button from "../../components/Button";
import SelectInputNumber from "../../components/SelectInputNumber";

export interface FormData {
  health_safety_management_responsibility: string;
  safety_management_duties: string;
  resource_allocation_for_safety_management: string;
  affiliation_to_general_risk_system: string;
  identification_of_high_risk_workers: string;
  copasst_formation: string;
  copasst_training: string;
  convivence_committee_formation: string;
  training_program_promotion_and_prevention: string;
  induction_and_reinduction: string;
  certified_responsibles_in_safety_management: string;
  signed_safety_policy: string;
  clear_measurable_goals: string;
  priority_evaluation_and_identification: string;
  action_plan_with_goals_and_timeline: string;
  documentation_retention: string;
  performance_reporting: string;
  legal_matrix: string;
  communication_mechanisms: string;
  evaluation_for_procurement: string;
  supplier_evaluation: string;
  impact_evaluation_of_internal_external_changes: string;
  sociodemographic_description: string;
  health_promotion_activities: string;
  occupational_medical_info: string;
  occupational_medical_evaluations: string;
  medical_records_custody: string;
  medical_labour_restrictions: string;
  healthy_lifestyle_environments: string;
  drinking_water_and_sanitation: string;
  waste_disposal: string;
  accident_and_disease_reporting: string;
  incident_investigation: string;
  accident_disease_statistics: string;
  accident_frequency_measurement: string;
  accident_severity_measurement: string;
  work_accident_mortality_measurement: string;
  occupational_disease_prevalence_measurement: string;
  occupational_disease_incidence_measurement: string;
  medical_cause_absenteeism_measurement: string;
  hazard_identification_methodology: string;
  all_level_hazard_identification: string;
  carcinogenic_toxic_substance_identification: string;
  environmental_measurements: string;
  hazard_prevention_and_control: string;
  prevention_control_verification: string;
  procedural_documentation: string;
  systematic_inspections: string;
  periodic_maintenance: string;
  personal_protection_equipment_distribution: string;
  emergency_preparedness_plan: string;
  prevention_brigade: string;
  sst_indicators_definition: string;
  annual_audit: string;
  annual_audit_review: string;
  audit_planning: string;
  preventive_corrective_actions: string;
  improvement_actions_based_on_reviews: string;
  accident_investigation_improvement_actions: string;
  improvement_plan_implementation: string;
  [key: string]: any; // Allow dynamic keys
}

const initialState = {
  health_safety_management_responsibility: "No aplica",
  safety_management_duties: "No aplica",
  resource_allocation_for_safety_management: "No aplica",
  affiliation_to_general_risk_system: "No aplica",
  identification_of_high_risk_workers: "No aplica",
  copasst_formation: "No aplica",
  copasst_training: "No aplica",
  convivence_committee_formation: "No aplica",
  training_program_promotion_and_prevention: "No aplica",
  induction_and_reinduction: "No aplica",
  certified_responsibles_in_safety_management: "No aplica",
  signed_safety_policy: "No aplica",
  clear_measurable_goals: "No aplica",
  priority_evaluation_and_identification: "No aplica",
  action_plan_with_goals_and_timeline: "No aplica",
  documentation_retention: "No aplica",
  performance_reporting: "No aplica",
  legal_matrix: "No aplica",
  communication_mechanisms: "No aplica",
  evaluation_for_procurement: "No aplica",
  supplier_evaluation: "No aplica",
  impact_evaluation_of_internal_external_changes: "No aplica",
  sociodemographic_description: "No aplica",
  health_promotion_activities: "No aplica",
  occupational_medical_info: "No aplica",
  occupational_medical_evaluations: "No aplica",
  medical_records_custody: "No aplica",
  medical_labour_restrictions: "No aplica",
  healthy_lifestyle_environments: "No aplica",
  drinking_water_and_sanitation: "No aplica",
  waste_disposal: "No aplica",
  accident_and_disease_reporting: "No aplica",
  incident_investigation: "No aplica",
  accident_disease_statistics: "No aplica",
  accident_frequency_measurement: "No aplica",
  accident_severity_measurement: "No aplica",
  work_accident_mortality_measurement: "No aplica",
  occupational_disease_prevalence_measurement: "No aplica",
  occupational_disease_incidence_measurement: "No aplica",
  medical_cause_absenteeism_measurement: "No aplica",
  hazard_identification_methodology: "No aplica",
  all_level_hazard_identification: "No aplica",
  carcinogenic_toxic_substance_identification: "No aplica",
  environmental_measurements: "No aplica",
  hazard_prevention_and_control: "No aplica",
  prevention_control_verification: "No aplica",
  procedural_documentation: "No aplica",
  systematic_inspections: "No aplica",
  periodic_maintenance: "No aplica",
  personal_protection_equipment_distribution: "No aplica",
  emergency_preparedness_plan: "No aplica",
  prevention_brigade: "No aplica",
  sst_indicators_definition: "No aplica",
  annual_audit: "No aplica",
  annual_audit_review: "No aplica",
  audit_planning: "No aplica",
  preventive_corrective_actions: "No aplica",
  improvement_actions_based_on_reviews: "No aplica",
  accident_investigation_improvement_actions: "No aplica",
  improvement_plan_implementation: "No aplica",
};

interface StandardItem {
  propName: string;
  text: string;
}

export interface PlanData {
  item_name?: string;
  customized_item_name?: string;
  activity?: string;
  manager?: number;
  participants?: number[];
  start_date?: string;
  end_date?: string;
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  is_customized_item?: boolean;
}

type OptionType = {
  label: string;
  value: number;
};

export function Autoevaluation() {
  const [formData, setFormData] = useState<FormData>(initialState);
  const [formDataModal, setFormDataModal] = useState<PlanData[]>([]);
  const [textError, setTextError] = useState<string>();
  const [completedBy, setCompletedBy] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);

  const [currentItems, setCurrentItems] = useState<StandardItem[]>(standarsItemsTeam3);
  const [currentItem, setCurrentItem] = useState<StandardItem | null>(null);
  const navigate = useNavigate();


  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        console.log('Datos recibidos:', response);

        const options = response.results.map((employee: EmployeesListData) => ({
          label: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        }));

        setEmployeeOptions(options);
      },
      (error) => {
        setTextError("Error al obtener los empleados.")
      }
    );
  }, []);
  
  const getItemsToShow = (numberEmployees: number) => {
    if (numberEmployees < 11) {
      return standarsItemsTeam1;
    } else if (numberEmployees >= 11 && numberEmployees < 50) {
      return standarsItemsTeam2;
    } else {
      return standarsItemsTeam3;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (value === "No cumple") {
      const item = currentItems.find(item => item.propName === name);
      if (item) {
        setCurrentItem(item);
        setShowModal(true);
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const handleModalSubmit = (planData: PlanData | any) => {
    if (currentItem) {
      setFormDataModal((prev) => {
        const updatedPlans = prev.filter(plan => plan.item_name !== currentItem.propName);
        return [...updatedPlans, { ...planData, propName: currentItem.propName }];
      });
    }
    setShowModal(false);
    setCurrentItem(null);
  };

  const handleEditPlan = (item: StandardItem) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const handleSubmit = () => {
  
    if (completedBy === 0) {
      setTextError("Debes añadir la persona que elaboró la autoevaluación")
      return;
    }
  
    const autoevaluationData = new AutoevaluationData(completedBy, formData);
  
    autoevaluationService.submitAutoevaluation(autoevaluationData,
      (response) => {
        setShowModalSuccess(true)

        if (formDataModal && formDataModal.length > 0) {
          autoevaluationService.submitPlanData(formDataModal,
            (response) => {
              console.log('Plan de Mejora enviado con éxito:');
              navigate(`/home`)
            },
            (error) => {
              console.error('Error al enviar el Plan de Mejora:', error);
              alert('Error al enviar el Plan de Mejora: ' + error);
            }
          );
        }
      },
      (error) => {
        console.error('Error al enviar Autoevaluación:', error);
        alert('Error al enviar la Autoevaluación: ' + error);
      }
    );
  };
  
  useEffect(() => {
    companyService.getCompanyData(
      (response) => {
        setCurrentItems(getItemsToShow(response.number_of_employees));
      },
      (error) => {
        console.error('Error al buscar la company:', error);
      },
    )
  }, [])

  const handleCloseModalSuccess = () => {
    setShowModal(false);
  };
  return (
    <>
    {showModalSuccess && (
        <SuccessModal
          message="Autoevaluación enviada con éxito"
          buttonText="Continuar"
          onClose={handleCloseModalSuccess}
          redirectPath="/home"
        />
      )}
    <div className={`grid h-auto  ${mainDivClassName}  p-2 `}>
      <div className="bg-white rounded-lg  w-full max-w-3xl border-2 border-secondaryBrown place-content-center">
        <HeaderSurveys title="Autoevaluación" />
        <div className='grid p-4 gap-10 items-center text-secondaryBrown'>
          {currentItems.map((item, index) => (
            <div className="grid gap-4 border-b-2  border-secondaryBrown  " key={index}>
              <div className="text-xl font-semibold ">
               {item.text}
              </div>
              <div className="flex  justify-around my-2 mobile:gap-4">
                <label className="flex items-center gap-2 ">
                  <input type="radio" name={item.propName} value="Cumple" onChange={handleChange} />
                  Cumple
                </label>
                <label className="flex items-center gap-2">
                  <input type="radio" name={item.propName} value="No cumple" onChange={handleChange} />
                  No cumple
                </label>
                <label className="flex items-center gap-2">
                  <input type="radio" name={item.propName} value="No aplica" onChange={handleChange} />
                  No aplica
                </label>
              </div>
              {formDataModal.find((plan) => plan.item_name === item.propName) && (
                <SecundaryButton
                  onClick={() => handleEditPlan(item)}
                  text='Editar Plan de Mejora'
                />
                  
              )}
            </div>
          ))}

          <SelectInputNumber
            id='completed-by-id'
            options={employeeOptions}
            label="Autoevaluación elaborada por"
            value={completedBy}
            onChange={(newValue) => setCompletedBy(newValue)} 
          />
          {textError && <span className="text-center text-red-600 text-sm">{textError}</span>}

          <Button  
            onClick={handleSubmit} 
            text='Enviar Autoevaluación' 
          />
          </div>
        </div>
      </div>
          
      {currentItem && (
        <ImprovementPlan
          show={showModal}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          itemText={currentItem.text}
          propName={currentItem.propName}
        />
      )}

    
    </>
    
  );
}

