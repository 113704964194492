import { formatDate } from "../components/improvementPlan/ImprovementPlanDetailsModal";
import { PlansDataInterfaceResponse } from "../services/autoevaluationService";
import { customizedItems } from "./customizedItems";
import { standarsItemsTeam3 } from "./standarItems";

const getItemText = (itemName?: string) => {
    const item = standarsItemsTeam3.find(i => i.propName === itemName);
    return item ? item.text : itemName;
  };

const getItemTextCustom = (itemName?: string) => {
  const item = customizedItems.find(i => i.propName === itemName);
  return item ? item.text : itemName;
};


  export const createRowsStandarsItems = (plans: PlansDataInterfaceResponse) => {
    return plans.standard_items?.map(plan => ({
      id: plan.id,
      item: getItemText(plan.item_name),
      itemName: plan.item_name,
      isCustomizedItem: plan.is_customized_item,
      activity: plan.activity,
      manager: plan.manager ? `${plan.manager.first_name} ${plan.manager.last_name}` : 'N/A',
      managerId: plan.manager ? plan.manager.id : null, 
      participants: plan.participants ? plan.participants.map(p => `${p.first_name} ${p.last_name}`).join(', ') : 'N/A',
      participantsIds: plan.participants ? plan.participants.map(p => p.id) : [], 
      startDate: formatDate(plan.start_date),
      endDate: formatDate(plan.end_date),
      evidences: plan.id, 
      state: plan.state,
    })) || [];
  };
  export const createRowsCustomItems = (plans: PlansDataInterfaceResponse) => {
    return plans.customized_items?.map(plan => ({
      id: plan.id,
      isCustomizedItem: plan.is_customized_item,
      item: getItemTextCustom(plan.customized_item_name),
      itemName: plan.customized_item_name,
      activity: plan.activity,
      manager: plan.manager ? `${plan.manager.first_name} ${plan.manager.last_name}` : 'N/A',
      managerId: plan.manager ? plan.manager.id : null, 
      participants: plan.participants ? plan.participants.map(p => `${p.first_name} ${p.last_name}`).join(', ') : 'N/A',
      participantsIds: plan.participants ? plan.participants.map(p => p.id) : [], 
      startDate: formatDate(plan.start_date),
      endDate: formatDate(plan.end_date),
      evidences: plan.id, 
      state: plan.state,
    })) || [];
  };

  export const columnsImprovementsPlans = [
    { id: 'item', label: 'Nombre del item' },
    { id: 'activity', label: 'Actividad' },
    { id: 'manager', label: 'Responsable' },
    { id: 'participants', label: 'Participantes' },
    { id: 'startDate', label: 'Fecha de Inicio' },
    { id: 'endDate', label: 'Fecha Límite' },
    { id: 'evidencias', label: 'Añadir Evidencia' },
    { id: 'showEvidence', label: 'Ver evidencia' },
    { id: 'state', label: 'Estado' },
    { id: 'edit', label: '' },
    { id: 'delete', label: '' },
  ];