import React, { useState } from 'react';
import evidenceIcon from '../assets/icons/evidence-icon.svg';
import { PlanEdit } from './EditImprovementPlan';
import deleteIcon from '../assets/icons/delete-icon.svg'
import ConfirmationDeleteModal from './ConfirmationDeleteModal';

interface Column {
  id: string;
  label: string;
  format?: (value: any) => string;
}

interface Row {
  [key: string]: any;
}

interface CustomTableProps {
  title: string;
  columns: Column[];
  rows: Row[];
  page: number;
  rowsPerPage: number;
  handleOpenModal: (item: string, id: string) => void;
  handleOpenModalShowEvidence: (itemName: string, activity: string) => void;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleEdit: (plan: PlanEdit) => void;
  handleDeleteClick: (id: string) => void
}
const parseDateString = (dateString: string): Date => {
  const months = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  const [day, month, year] = dateString.split(' de ');
  const monthIndex = months.indexOf(month.toLowerCase());

  if (day && monthIndex >= 0 && year) {
    return new Date(`${year}-${monthIndex + 1}-${day}`);
  }

  return new Date(); // Fecha predeterminada si el formato no es válido
};

const ImprovementsPlansTable: React.FC<CustomTableProps> = ({
  title,
  columns,
  rows,
  page,
  rowsPerPage,
  handleOpenModal,
  handleOpenModalShowEvidence,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEdit,
  handleDeleteClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const handleEditClick = (row: Row) => {
    const plan: PlanEdit = {
      id: row.id,
      item: row.itemName,
      activity: row.activity,
      manager: row.managerId,
      participants: row.participantsIds || [], 
      startDate: row.startDate ? parseDateString(row.startDate) : null,
      endDate: row.endDate ? parseDateString(row.endDate) : null,
      evidences: row.evidences || '', 
      state: row.state || '', 
      isCustomizedItem: row.isCustomizedItem,
    };
  
    handleEdit(plan);
  };

  const handleDeleteClickWithConfirmation = (id: string) => {
    setItemToDelete(id);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      handleDeleteClick(itemToDelete);
      setItemToDelete(null);
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setItemToDelete(null);
    setIsModalOpen(false);
  };

  
  

  return (
    <div className="my-14">
      <div className="text-xl text-start font-semibold text-secondaryOrange mb-2 ml-2">{title}</div>
      <div className='overflow-hidden w-full'>
      <table className="min-w-full   bg-white border-separate border border-spacing-x-2 border-transparent">
        <thead>
          <tr className="w-full">
            {columns.map((column) => (
              <th
                key={column.id}
                className='text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg'
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-secondaryBrown text-center">
          {rows.length > 0 ? (
            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {columns.map((column) => {
                  return (
                    <td key={column.id} className="px-4 py-3 text-sm">
                      {column.id === 'planDeMejora' ? (
                        <button
                          className={`text-primaryOrange ${!row.planDeMejora ? 'text-secondaryOrange cursor-not-allowed' : ''}`}
                          onClick={() => handleOpenModal(row.item + ' ' + row.estandar, 'Plan de Mejora')}
                          disabled={!row.planDeMejora}
                        >
                          Plan de mejora
                        </button>
                      ) : column.id === 'evidencias' ? (
                        <button
                          type='button'
                          onClick={() => handleOpenModal(row.item, row.evidences)}
                        >
                          <img src={evidenceIcon} alt="evidence icon" className="mr-2" />
                        </button>

                      ) : column.id === 'showEvidence' ? (

                        <button
                          onClick={() => handleOpenModalShowEvidence(row.itemName, row.activity)}
                          className='text-primaryOrange'
                        >
                          Ver evidencias
                        </button>
                          
                      ) : column.id === 'edit' ? (
                        <button
                          type="button"
                          onClick={() => handleEditClick(row)}
                          className="text-secondaryOrange"
                        >
                          Editar
                        </button>
                      ) : column.id === 'delete' ? (
                        <button
                            type="button"
                            onClick={() => handleDeleteClickWithConfirmation(row.id)}
                          >
                            <img src={deleteIcon} alt="delete icon" className="min-w-4 min-h-4 max-w-4 max-h-4" />
                          </button>
                      ) : (
                        <span>{row[column.id]}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                No hay {title} disponibles.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      <div className="flex justify-between items-center py-2">
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page - 1)}
          disabled={page === 0}
        >
          Anterior
        </button>
        <div>
          <label className="mr-2 text-primaryBrown">Número de filas</label>
          <select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            className="border border-gray-300 rounded-md px-2 py-1"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={100}>100</option>
          </select>
        </div>
        <button
          className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          onClick={() => handleChangePage(page + 1)}
          disabled={page >= Math.ceil(rows.length / rowsPerPage) - 1}
        >
          Siguiente
        </button>
      </div>
      <ConfirmationDeleteModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        message="¿Estás seguro de que quieres eliminar esta actividad?"
      />
    </div>
  );
};

export default ImprovementsPlansTable;
