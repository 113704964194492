import React, { useState, useEffect } from 'react';
import { EmployeesListData, employeesService } from '../../services/employeesService';
import HeaderSurveys from '../HeaderSurveys';
import TextFieldString from '../TextFieldString';
import SelectInputNumber from '../SelectInputNumber';
import MultiSelectInput from '../SelectInputNumberMultiple';
import TextFieldDate from '../TextFieldDate';
import Button from '../Button';
import CancelButton from '../CancelButton';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (planData: PlanData) => void;
  initialData?: PlanData;
  itemText: string;
  propName?: string;
  isCustomItem?: boolean;
}

interface PlanData {
  item_name?: string;
  activity?: string;
  manager?: number;
  participants?: number[];
  start_date?: string; 
  end_date?: string;   
  is_work_plan?: boolean;
  is_training_plan?: boolean;
  is_customized_item?: boolean;
}

type OptionType = {
  label: string;
  value: number;
};

const ImprovementPlan: React.FC<ModalProps> = ({
  show, onClose, onSubmit, itemText, initialData, propName, isCustomItem,
}) => {
  const [activity, setActivity] = useState(initialData?.activity || "");
  const [manager, setManager] = useState(initialData?.manager || 0);
  const [participants, setParticipants] = useState<number[]>(initialData?.participants || []);
  const [start_date, setStartDate] = useState<Date | null>(initialData?.start_date ? new Date(initialData.start_date) : null);
  const [end_date, setEndDate] = useState<Date | null>(initialData?.end_date ? new Date(initialData.end_date) : null);
  const [is_work_plan, setIsWorkPlan] = useState(initialData?.is_work_plan || false);
  const [is_training_plan, setIsTrainingPlan] = useState(initialData?.is_training_plan || false);
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
  const [textError, setTextError] = useState<string>("");

  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        const options = response.results.map((employee: EmployeesListData) => ({
          label: `${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        }));
        setEmployeeOptions(options);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  }, []);

  useEffect(() => {
    if (initialData) {
      setActivity(initialData.activity || "");
      setManager(initialData.manager || 0);
      setParticipants(initialData.participants || []);
      setStartDate(initialData.start_date ? new Date(initialData.start_date) : null);
      setEndDate(initialData.end_date ? new Date(initialData.end_date) : null);
      setIsWorkPlan(initialData.is_work_plan || false);
      setIsTrainingPlan(initialData.is_training_plan || false);
    }
  }, [initialData]);

  const handleSubmit = () => {
    setTextError("");

    if (start_date && end_date && end_date < start_date) {
      setTextError("La fecha final no puede ser menor que la fecha inicial.");
      return;
    }

    const planData: PlanData = {
      item_name: propName,
      activity,
      manager,
      participants,
      start_date: start_date?.toISOString() || "", // Formato ISO
      end_date: end_date?.toISOString() || "",     // Formato ISO
      is_work_plan,
      is_training_plan,
      is_customized_item: isCustomItem,
    };

    onSubmit(planData);
  };

  const handleParticipantsChange = (selectedValues: number[]) => {
    setParticipants(selectedValues);
  };

  useEffect(() => {
    if (end_date && start_date && end_date < start_date) {
      setEndDate(start_date);
    }
  }, [start_date]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex justify-center  items-center bg-gray-800 bg-opacity-50 p-2">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mobile:max-h-screen mobile:overflow-y-auto border-2 border-secondaryBrown">
        <HeaderSurveys title={`Crear plan de mejora para item ${itemText}`} />
        <div className="flex flex-col justify-center items-center p-4">
          <TextFieldString
            label="Actividad a realizar"
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
          />
          <SelectInputNumber
            id="manager-id"
            options={employeeOptions}
            label="Responsable"
            value={manager}
            onChange={(newValue) => setManager(newValue)}
          />
          <MultiSelectInput
            id="participants-id"
            options={employeeOptions}
            label="Participantes"
            values={participants}
            onChange={handleParticipantsChange}
          />
          <TextFieldDate
            label="Fecha de inicio"
            value={start_date}
            onChange={(e) => setStartDate(e.target.valueAsDate)}
          />
          <TextFieldDate
            label="Fecha de fin"
            value={end_date}
            onChange={(e) => setEndDate(e.target.valueAsDate)}
          />
          <div className="flex gap-8">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={is_work_plan}
                onChange={(e) => setIsWorkPlan(e.target.checked)}
                className="form-checkbox"
              />
              <span className="ml-2">Plan de Trabajo</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={is_training_plan}
                onChange={(e) => setIsTrainingPlan(e.target.checked)}
                className="form-checkbox"
              />
              <span className="ml-2">Plan de Capacitación</span>
            </label>
          </div>
          {textError && <span className="text-red-600 text-sm">{textError}</span>}
        </div>
        <div className="flex justify-center gap-2 p-4">
          <Button text="Guardar" onClick={handleSubmit} />
          <CancelButton text="Cancelar" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ImprovementPlan;
