import React, { ReactNode } from 'react';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar'; // Asegúrate de importar Sidebar si es necesario

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <div className="w-px bg-secondaryOrange h-screen fixed left-32 mobile:left-0 top-0 mt-4"></div>

        <div className="flex-grow ml-32 mobile:ml-0 mt-32 p-4 ">
          <main className="flex-1 w-full">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
