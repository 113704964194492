export const standarsItemsTeam1 = [
  { text: "1.1.1 Responsable del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "health_safety_management_responsibility" },
  { text: "1.1.4 Afiliación al Sistema General de Riesgos Laborales", propName: "affiliation_to_general_risk_system" },
  { text: "1.2.1 Programa Capacitación promoción y prevención – PyP", propName: "training_program_promotion_and_prevention" },
  { text: "2.4.1 Plan que identifica objetivos, metas, responsabilidad, recursos con cronograma y firmado", propName: "action_plan_with_goals_and_timeline" },
  { text: "3.1.4 Realización de Evaluaciones Médicas Ocupacionales -Peligros- Periodicidad- Comunicación al Trabajador", propName: "occupational_medical_evaluations" },
  { text: "4.1.2 Identificación de peligros con participación de todos los niveles de la empresa", propName: "all_level_hazard_identification" },
  { text: "4.2.1 Implementación de medidas de prevención y control de peligros/riesgos identificados", propName: "hazard_prevention_and_control" },
]

export const standarsItemsTeam2 = [
  { text: "1.1.1 Responsable del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "health_safety_management_responsibility" },
  { text: "1.1.3 Asignación de recursos para el Sistema de Gestión de Seguridad y Salud en el Trabajo – SG-SST", propName: "resource_allocation_for_safety_management" },
  { text: "1.1.4 Afiliación al Sistema General de Riesgos Laborales", propName: "affiliation_to_general_risk_system" },
  { text: "1.1.6 Conformación COPASST o Vigia", propName: "copasst_formation" },
  { text: "1.1.8 Conformación Comité Convivencia", propName: "copasst_training" },
  { text: "1.2.1 Programa Capacitación promoción y prevención – PyP", propName: "training_program_promotion_and_prevention" },
  { text: "2.1.1 Política del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST firmada, fechada y comunicada al COPASST", propName: "signed_safety_policy" },
  { text: "2.4.1 Plan que identifica objetivos, metas, responsabilidad, recursos con cronograma y firmado", propName: "action_plan_with_goals_and_timeline" },
  { text: "2.5.1 Archivo o retención documental del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "documentation_retention" },
  { text: "2.8.1 Mecanismos de comunicación, auto reporte en Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "communication_mechanisms" },
  { text: "3.1.1 Descripción sociodemográfica – Diagnóstico de condiciones de salud", propName: "sociodemographic_description" },
  { text: "3.1.2 Actividades de Promoción y Prevención en Salud", propName: "health_promotion_activities" },
  { text: "3.1.4 Realización de Evaluaciones Médicas Ocupacionales -Peligros- Periodicidad- Comunicación al Trabajador", propName: "occupational_medical_evaluations" },
  { text: "3.1.6 Restricciones y recomendaciones médico/laborales", propName: "medical_labour_restrictions" },
  { text: "3.2.1 Reporte de los Accidentes de Trabajo y Enfermedad Laboral a la ARL, EPS y Dirección Territorial del Ministerio de Trabajo", propName: "accident_and_disease_reporting" },
  { text: "3.2.2 Investigación de incidentes, accidentes y enfermedades laborales", propName: "incident_investigation" },
  { text: "4.1.2 Identificación de peligros con participación de todos los niveles de la empresa", propName: "all_level_hazard_identification" },
  { text: "4.2.5 Mantenimiento periódico de instalaciones, equipos, máquinas, herramientas", propName: "periodic_maintenance" },
  { text: "4.2.6 Entrega de Elementos de Protección Personal EPP, se verifica con contratistas y subcontratistas", propName: "personal_protection_equipment_distribution" },
  { text: "5.1.1 Se cuenta con el Plan de Prevención, Preparación y Respuesta ante emergencias", propName: "emergency_preparedness_plan" },
  { text: "5.1.2 Brigada de prevención conformada, capacitada y dotada", propName: "prevention_brigade" }
];

export const standarsItemsTeam3 = [
    { text: "1.1.1 Responsable del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "health_safety_management_responsibility" },
    { text: "1.1.2 Responsabilidades en el Sistema de Gestión de Seguridad y Salud en el Trabajo – SG-SST", propName: "safety_management_duties" },
    { text: "1.1.3 Asignación de recursos para el Sistema de Gestión de Seguridad y Salud en el Trabajo – SG-SST", propName: "resource_allocation_for_safety_management" },
    { text: "1.1.4 Afiliación al Sistema General de Riesgos Laborales", propName: "affiliation_to_general_risk_system" },
    { text: "1.1.5 Identificación de trabajadores de alto riesgo y cotización de pensión especial", propName: "identification_of_high_risk_workers" },
    { text: "1.1.6 Conformación COPASST", propName: "copasst_formation" },
    { text: "1.1.7 Capacitación COPASST", propName: "copasst_training" },
    { text: "1.1.8 Conformación Comité Convivencia", propName: "convivence_committee_formation" },
    { text: "1.2.1 Programa Capacitación promoción y prevención – PyP", propName: "training_program_promotion_and_prevention" },
    { text: "1.2.2 Inducción y Reinducción en Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST, actividades de Promoción y Prevención PyP", propName: "induction_and_reinduction" },
    { text: "1.2.3 Responsables del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST con curso virtual de 50 horas", propName: "certified_responsibles_in_safety_management" },
    { text: "2.1.1 Política del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST firmada, fechada y comunicada al COPASST", propName: "signed_safety_policy" },
    { text: "2.2.1 Objetivos definidos, claros, medibles, cuantificables, con metas, documentados, revisados del SG-SST", propName: "clear_measurable_goals" },
    { text: "2.3.1 Evaluación e identificación de prioridades", propName: "priority_evaluation_and_identification" },
    { text: "2.4.1 Plan que identifica objetivos, metas, responsabilidad, recursos con cronograma y firmado", propName: "action_plan_with_goals_and_timeline" },
    { text: "2.5.1 Archivo o retención documental del Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "documentation_retention"},
    { text: "2.6.1 Rendición sobre el desempeño", propName: "performance_reporting" },
    { text: "2.7.1 Matriz legal", propName: "legal_matrix" },
    { text: "2.8.1 Mecanismos de comunicación, auto reporte en Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "communication_mechanisms" },
    { text: "2.9.1 Identificación, evaluación, para adquisición de productos y servicios en Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "evaluation_for_procurement" },
    { text: "2.10.1 Evaluación y selección de proveedores y contratistas", propName: "supplier_evaluation" },
    { text: "2.11.1 Evaluación del impacto de cambios internos y externos en el Sistema de Gestión de Seguridad y Salud en el Trabajo SG-SST", propName: "impact_evaluation_of_internal_external_changes" },
    { text: "3.1.1 Descripción sociodemográfica – Diagnóstico de condiciones de salud", propName: "sociodemographic_description" },
    { text: "3.1.2 Actividades de Promoción y Prevención en Salud", propName: "health_promotion_activities" },
    { text: "3.1.3 Información al médico de los perfiles de cargo", propName: "occupational_medical_info" },
    { text: "3.1.4 Realización de Evaluaciones Médicas Ocupacionales -Peligros- Periodicidad- Comunicación al Trabajador", propName: "occupational_medical_evaluations" },
    { text: "3.1.5 Custodia de Historias Clínicas", propName: "medical_records_custody" },
    { text: "3.1.6 Restricciones y recomendaciones médico/laborales", propName: "medical_labour_restrictions" },
    { text: "3.1.7 Estilos de vida y entornos saludables (controles tabaquismo, alcoholismo, farmacodependencia y otros)", propName: "healthy_lifestyle_environments" },
    { text: "3.1.8 Agua potable, servicios sanitarios y disposición de basuras", propName: "drinking_water_and_sanitation" },
    { text: "3.1.9 Eliminación adecuada de residuos sólidos, líquidos o gaseosos", propName: "waste_disposal" },
    { text: "3.2.1 Reporte de los Accidentes de Trabajo y Enfermedad Laboral a la ARL, EPS y Dirección Territorial del Ministerio de Trabajo", propName: "accident_and_disease_reporting" },
    { text: "3.2.2 Investigación de incidentes, accidentes y enfermedades laborales", propName: "incident_investigation" },
    { text: "3.2.3 Registro y análisis estadístico de accidentes y enfermedades laborales", propName: "accident_disease_statistics" },
    { text: "3.3.1 Medición de la frecuencia de la accidentalidad", propName: "accident_frequency_measurement" },
    { text: "3.3.2 Medición de la severidad de la accidentalidad", propName: "accident_severity_measurement" },
    { text: "3.3.3 Medición de la mortalidad por Accidentes de Trabajo", propName: "work_accident_mortality_measurement" },
    { text: "3.3.4 Medición de la prevalencia de Enfermedad Laboral", propName: "occupational_disease_prevalence_measurement" },
    { text: "3.3.5 Medición de la incidencia de Enfermedad Laboral", propName: "occupational_disease_incidence_measurement" },
    { text: "3.3.6 Medición del ausentismo por causa médica", propName: "medical_cause_absenteeism_measurement" },
    { text: "4.1.1 Metodología para la identificación de peligros, evaluación y valoración de los riesgos", propName: "hazard_identification_methodology" },
    { text: "4.1.2 Identificación de peligros con participación de todos los niveles de la empresa", propName: "all_level_hazard_identification" },
    { text: "4.1.3 Identificación de sustancias catalogadas como carcinógenas o con toxicidad aguda", propName: "carcinogenic_toxic_substance_identification" },
    { text: "4.1.4 Realización mediciones ambientales, químicos, físicos y biológicos", propName: "environmental_measurements" },
    { text: "4.2.1 Implementación de medidas de prevención y control de peligros/riesgos identificados", propName: "hazard_prevention_and_control" },
    { text: "4.2.2 Verificación de aplicación de medidas de prevención y control por parte de los trabajadores", propName: "prevention_control_verification" },
    { text: "4.2.3 Elaboración de procedimientos, instructivos, fichas, protocolos", propName: "procedural_documentation" },
    { text: "4.2.4 Realización de inspecciones sistemáticas a las instalaciones, maquinaria o equipos con la participación del COPASST", propName: "systematic_inspections" },
    { text: "4.2.5 Mantenimiento periódico de instalaciones, equipos, máquinas, herramientas", propName: "periodic_maintenance" },
    { text: "4.2.6 Entrega de Elementos de Protección Personal EPP, se verifica con contratistas y subcontratistas", propName: "personal_protection_equipment_distribution" },
    { text: "5.1.1 Se cuenta con el Plan de Prevención, Preparación y Respuesta ante emergencias", propName: "emergency_preparedness_plan" },
    { text: "5.1.2 Brigada de prevención conformada, capacitada y dotada", propName: "prevention_brigade" },
    { text: "6.1.1 Definición de indicadores del SG-SST de acuerdo condiciones de la empresa", propName: "sst_indicators_definition" },
    { text: "6.1.2 Las empresa adelanta auditoría por lo menos una vez al año", propName: "annual_audit" },
    { text: "6.1.3 Revisión anual por la alta dirección, resultados y alcance de la auditoría", propName: "annual_audit_review" },
    { text: "6.1.4 Planificación auditorías con el COPASST", propName: "audit_planning" },
    { text: "7.1.1 Definición de acciones preventivas y correctivas con base en resultados del SG-SST", propName: "preventive_corrective_actions" },
    { text: "7.1.2 Acciones de mejora conforme a revisión de la alta dirección", propName: "improvement_actions_based_on_reviews" },
    { text: "7.1.3 Acciones de mejora con base en investigaciones de accidentes de trabajo y enfermedades laborales", propName: "accident_investigation_improvement_actions" },
    { text: "7.1.4 Elaboración Plan de Mejoramiento e implementación de medidas y acciones correctivas solicitadas por autoridades y ARL", propName: "improvement_plan_implementation" }
  ];
  