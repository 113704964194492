import { useNavigate, useSearchParams } from 'react-router-dom';
import { printPayment } from '../../components/payment/printPayment';
import { useEffect, useState } from 'react';
import { paymentService } from '../../services/paymentService';
import LabelValueDisplay from '../../components/LabelValueDisplay';
import Button from '../../components/Button';
import { mainDivClassName } from '../../utils/MainDivClassName';
import HeaderSurveys from '../../components/HeaderSurveys';
import SecundaryButton from '../../components/SecundaryButton';


type Props = {}

const PaymentResultView = (props: Props) => {
    const [searchParams] = useSearchParams();
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0.0);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [reference, setReference] = useState('');
    const navigate = useNavigate();

    const handleCLick = () => {
        navigate("/")
    }
    const handlePrint = () => {
        const paymentData = {
            name: name ?? "",
            amount: amount ?? "",
            status: status ?? "",
            email: email ?? "",
            reference: reference ?? "",
        };
        printPayment(paymentData);

    };

    const transactionId = searchParams.get('id');

    useEffect(
        () => {
            if (!transactionId) {
                navigate("/");
            }
            paymentService.getPaymentResult(
                transactionId!,
                (response) => {
                    setName(response.name);
                    setAmount(response.amount);
                    setEmail(response.email);
                    setStatus(response.status);
                    setReference(response.reference);
                },
                (error) => { },
            );
        },
        []
    );

    return (
        
<div className={`grid h-screen ${mainDivClassName}  p-2`}>
    <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-2 border-secondaryBrown">
    <HeaderSurveys title="Resultado de pago" />
    <div className='p-4'>
        <LabelValueDisplay
            label='Empresa'
            value={name}
        />
        <LabelValueDisplay
            label='Monto'
            value={amount.toString()}
        />
        <LabelValueDisplay
            label='Email'
            value={email}
        />
        <LabelValueDisplay
            label='Referencia'
            value={reference}
        />
        <LabelValueDisplay
            label='Estado'
            value={status === "APPROVED" ? "Aprobado" : ""}
        />
        <div className="flex justify-center items-center mt-4 gap-4">
        <Button
            text='Ir al login'
            onClick={handleCLick}
            
        />
        <SecundaryButton
            text='Imprimir'
            onClick={handlePrint}
        />
        </div>
    </div>
   </div>
 </div>
    )
}

export default PaymentResultView
