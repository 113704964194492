import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

// Define tipos para los callbacks
interface ResponseData {
  backup_id :string
}
type OnSuccessDownload = (response: ResponseData) => void;
type OnErrorDownload = (error: any) => void;

// Función para descargar un archivo .zip
const downloadBackup = (
  onSuccess: OnSuccessDownload,
  onError: OnErrorDownload
): void => {
  axios.post(`${API_URL}/backup/`,{},{
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
      'Content-Type': 'application/json'
    },
  })
  .then(response => {
    onSuccess(response.data);
  })
  .catch(error => {
    onError(error);
  });
};

interface CheckStatusResponse {
  status: string;
  download_url?: string;
  error?: string;
}

type OnSuccessCheckStatus = (response: CheckStatusResponse) => void;
type OnErrorCheckStatus = (error: any) => void;

// Función para chequear el estado de la tarea
const checkBackupStatus = (
  backupId: string,
  onSuccess: OnSuccessCheckStatus,
  onError: OnErrorCheckStatus
): void => {
  const url = `${API_URL}/backup/check-backup-status/${backupId}/`;
  
  axios.get(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
  .then(response => {
    onSuccess(response.data);
  })
  .catch(error => {
    console.error('Error checking backup status:', error);
    onError(error);
  });
};

export const backupService = {
  checkBackupStatus,
  downloadBackup
}

