import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { authService } from '../services/authServices';
import { setLoggedIn } from '../features/userSlice';
import menuIcon from '../assets/icons/menu-icon.svg';

type Props = {};

const UserMenu = (props: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const completeLogout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    navigate('/');
  };

  const handleLogout = () => {
    authService.logout(
      completeLogout,
      (err) => {
        if (err?.response?.status === 401) {
          completeLogout();
        } else {
          alert(`Error durante cierre de sesión: ${err.message || err}`);
        }
      }
    );
  };

  return (
    <div className="relative">
      <button
        className="text-red-500 focus:outline-none"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <img src={menuIcon} alt="Menu" className="w-14 h-14" />
      </button>
      {menuOpen && (
        <div className="absolute right-0 z-20 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
          <ul className="py-1">
          <li>
            <button
              className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>navigate('/')}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>navigate('/absenteeism')}
            >
              Ausentismo
            </button>
          </li>
          <li>
            <button
              className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>navigate('/work-plan')}
            >
              Planes de trabajo
            </button>
          </li>
          <li>
            <button
              className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>navigate('/training-plan')}
            >
              Plan de capacitación
            </button>
          </li>
          <li>
            <button
              className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>navigate('/employees')}
            >
              Empleados
            </button>
          </li>
            <li>
              <button
                className="w-full text-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={handleLogout}
              >
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
