import React from 'react';

type CircularProgressProps = {
  percentage: number;
  text: string;
};

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage, text }) => {
  return (
    <div className="flex flex-col text-center items-center relative">
      <div className="relative w-32 h-32 desktopL:w-40 desktopL:h-40">
        <div className="absolute inset-0 w-full h-full rounded-full">
          <div
            className="absolute inset-0 rounded-full"
            style={{
              background: `conic-gradient(rgba(244, 151, 10, 0.5) ${percentage * 3.6}deg, transparent 0deg)`,
            }}
          ></div>
        </div>

        <div className="absolute inset-1 w-[95%] h-[95%] rounded-full bg-secondaryGray z-10 border-[1px] border-secondaryBrown"></div>

        <span className="absolute inset-0 flex items-center justify-center text-secondaryBrown text-xxl font-semibold z-20">
          {percentage.toFixed()}
          <span className="text-lg align-top">%</span>
        </span>
      </div>
  <p className=" text-lg font-semibold mt-2 text-primaryGray">{text}</p>
</div>




  );
};

export default CircularProgress;
