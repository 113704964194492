import { useEffect, useState } from 'react';
import { Box, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Button from '../../components/Button';
import MainLayout from '../../components/MainLayout';
import { DataWorkEnv, uploadImageService } from '../../services/uploadImageServices';
import CreateEmployeeModal from '../../components/createEmployee/CreateEmployeeModal';
import { EmployeesBatchData, EmployeesListData, employeesService } from '../../services/employeesService';
import AddImages from '../../components/uploadImagesAndVideo/AddImages';
import EvidenceModal from '../../components/evidenceModal';
import { autoevaluationService, ImprovementPlansData, GetImprovementPlansByEmployeeParams } from '../../services/autoevaluationService';
import ImprovementPlansPerEmployeeModal from '../../components/ImprovementPlansPerEmployee';
import { companyService, MissingEmployee, MissingResponse } from '../../services/companyServices';
import evidenceIcon from '../../assets/icons/evidence-icon.svg'
import { ServiceSurveys } from '../../services/employeeSurveysService';
import CancelButton from '../../components/CancelButton';

const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'id_card', label: 'Cédula' },
  { id: 'phone', label: 'Celular' },
  { id: 'email', label: 'Correo' },
  { id: 'role', label: 'Rol' },
  { id: 'status', label: 'Estado' },
  { id: 'actions', label: '' },
  { id: 'resend', label: '' },
  { id: 'evidence', label: 'Evidencia' },
  { id: 'edit', label: 'Editar' },
];
const ModuleEmployees = () => {
  const [showModal, setShowModal] = useState(false);
  const [employeesList, setEmployeesList] = useState<EmployeesListData[]>([]);
  const [employee, setEmployee] = useState<EmployeesListData>();
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [showCreateEvidenceModal, setShowCreateEvidenceModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | undefined>(undefined);
  const [next, setNext] = useState<string | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [previous, setPrevious] = useState<string | undefined>(undefined);
  const [workEnv, setWorkEnv] = useState<DataWorkEnv[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [evidenceType, setEvidenceType] = useState<string>('');
  const [improvementPlansPerEmployee, setImprovementPlansPerEmployee] = useState<ImprovementPlansData[]>([]);
  const [showImprovementPlansModal, setShowImprovementPlansModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState<boolean>(false);

  const [showExportModal, setShowExportModal] = useState(false);
  const [errorMessagePlans, setErrorMessagePlans] = useState<string>();
  const [errorMessageSurvey, setErrorMessageSurvey] = useState<string>();
  const [missingData, setMissingData] = useState<MissingResponse>()
  const [showEditModal, setShowEditModal] = useState(false);

const handleEditEmployee = (employee: EmployeesListData) => {
  setEmployee(employee);
  setShowEditModal(true);
};

const handleCloseEditModal = () => {
  setShowEditModal(false);
  setEmployee(undefined);
};

const handleEmployeeUpdate = (formData: any) => {
  employeesService.updateEmployee(
    formData.id, 
    formData,
    () => {
      setShowEditModal(false);
      setSuccessMessage("Empleado actualizado correctamente ✅");
      setTimeout(() => {
        setSuccessMessage(undefined);
      }, 1500);
      fetchEmployees();
      setEmployee(undefined);
    },
    err => {
      console.log(err);
      setErrorMessage("Error al actualizar el empleado");
      setTimeout(() => {
        setErrorMessage(undefined);
      }, 1500);
    }
  );
};


  const fetchEmployees = (url?: string) => {
    employeesService.employeesData(
      (response) => {
        setEmployeesList(response.results);
        setNext(response.next);
        setPrevious(response.previous);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      url
    );
  };

  const handleDownload = (response: BlobPart, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadMatriz = () => {
    companyService.downloadMatrizExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'Matriz.xlsx');
      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de la matriz en el año ${selectedYear}`)
      }
    );
  };
  const handleDownloadHealthConditions = () => {
    companyService.downloadHealthExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'CondicionesDeSalud.xlsx');
      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de condiciones de salud en el año ${selectedYear}`)
      }
    );
  };

  const handleDownloadWorkConditions = () => {
    companyService.downloadWorkExcel(
      selectedYear,
      (response) => {
        setShowExportModal(false);
        setErrorMessageSurvey("")
        handleDownload(response, 'CondicionesDeTrabajo.xlsx');

      },
      (error) => {
        console.error("Error downloading file:", error);
        setErrorMessageSurvey(`No tienes registros de condiciones de trabajo en el año ${selectedYear}`)
      }
    );
  };

  const handleExportOptionClick = (type: string) => {
    switch (type) {
      case 'matrix':
        handleDownloadMatriz();
        break;
      case 'healthConditions':
        handleDownloadHealthConditions();
        break;
      case 'workConditions':
        handleDownloadWorkConditions();
        break;
      default:
        console.error('Unknown export type');
    }
  };
  const handleOptionSuvey = (type: string) => {
    setErrorMessage('');
    setSuccessMessage('');
    ServiceSurveys.resendSurvey(
      employee!.id, type,
      () => setSuccessMessage('La encuesta se ha reenviado correctamente.'),
      () => setErrorMessage('Error al reenviar la encuesta.'),
    );
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleCreateEmployee = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleStatusChange = (employee: EmployeesListData) => {
    const updatedStatus = employee.status === 'Activo' ? 'Inactivo' : 'Activo';

    const empleadoActualizado = {
      first_name: employee.first_name,
      last_name: employee.last_name,
      id_card: employee.id_card,
      phone: employee.phone,
      email: employee.email,
      role: employee.role,
      status: updatedStatus,
    };
    employeesService.updateEmployee(
      employee.id,
      empleadoActualizado,
      response => {
        setSuccessMessage("Empleado actualizado ✅");
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, 1500);
        fetchEmployees();
      },
      err => {
        console.log(err);
        setErrorMessage("Error al actualizar el estado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    );
    setSuccessMessage(" ");
  };

  const handleEmployeeCreate = (formData: any) => {
    employeesService.registerEmployee(
      new EmployeesBatchData([formData]),
      () => {
        setShowModal(false);
        fetchEmployees();
        setSuccessMessage("Empleado creado correctamente ✅");
        setTimeout(() => {
          setSuccessMessage(undefined);
        }, 1500);
      },
      err => {
        console.log(err);
        setErrorMessage("Error al crear empleado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    );
  };

  const handleEvidenceClick = (employeeId: number) => {
    setSelectedEmployeeId(employeeId);
    setEvidenceType('');
    setShowCreateEvidenceModal(true);
  };
  const handleSurveyClick = (employee: EmployeesListData) => {
    setEmployee(employee);
    setShowSurveyModal(true);
  };

  const handleEvidenceTypeClick = (type: string) => {
    setEvidenceType(type);

    if (type === 'work') {
      uploadImageService.getworkEvidence(
        selectedEmployeeId!,
        (response) => {
          console.log('Datos recibidos:', response);
          setWorkEnv(response.results);
          if (response.results.length > 0) {
            setShowEvidenceModal(true);
          }
          setShowCreateEvidenceModal(false);
        },
        (error) => {
          console.log('Error al obtener los datos:', error);
          setShowCreateEvidenceModal(true);
        }
      );
    } else if (type === 'improvement') {
      const currentYear = new Date().getFullYear();

      autoevaluationService.getImprovementPlansPerEmployee(
        new GetImprovementPlansByEmployeeParams(currentYear, selectedEmployeeId!),
        (response) => {
          console.log("Planes de mejora por employee: ", response.improvement_plans);
          setImprovementPlansPerEmployee(response.improvement_plans);
          if (response.improvement_plans.length > 0) {
            setShowImprovementPlansModal(true);
          }
          setShowCreateEvidenceModal(false);

        },
        (error) => {
          console.error('Error al obtener los datos:', error);
          setErrorMessagePlans('No tiene planes de mejora')
          setShowCreateEvidenceModal(true);

        },
      );
    }
  };

  const handleCloseEvidenceModal = () => {
    setShowEvidenceModal(false);
    setShowCreateEvidenceModal(false);
    setSelectedEmployeeId(undefined);
    setEvidenceType('');
    setErrorMessagePlans('')
  };

  const handleCloseImprovementPlansModal = () => {
    setShowImprovementPlansModal(false);
  };

  const getMissingEmployee = (year: number) => {
    companyService.getMissingEmployees(
      year,
      response => {
        setMissingData(response)
      },
      err => {
        console.log(err);
        setErrorMessage("Error al actualizar el estado");
        setTimeout(() => {
          setErrorMessage(undefined);
        }, 1500);
      },
    )
  };
  useEffect(() => {
    getMissingEmployee(selectedYear)
  }, [selectedYear])


  const handleOpenExportModal = () => {
    getMissingEmployee(selectedYear)
    setShowExportModal(true);
  };

  const handleCloseExportModal = () => {
    setShowExportModal(false);
    setErrorMessageSurvey("")
  };
  const handleCloseSurveyModal = () => {
    setShowSurveyModal(false)
    setErrorMessage("")
    setSuccessMessage("")
  };
  const renderMissingEmployees = (employees?: MissingEmployee[]) => {
    if (!employees || employees.length === 0) {
      return null; 
    }
  
    return (
      <ul className='list-disc pl-5 text-secondaryBrown'>
        {employees.map((employee, index) => (
          <li key={index}>{employee.first_name} {employee.last_name}</li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <MainLayout>
        <div className='text-center text-secondaryBrown '>
          <h1 className="text-4xl text-start font-bold mb-16 text-primaryOrange">Empleados</h1>
          <div className='flex gap-9'>

            <button
              onClick={handleCreateEmployee}
              className="bg-primaryGreen py-2 px-4  text-black font-semibold rounded-lg  hover:bg-secondaryGreen"
            >
              Añadir empleado
            </button>
            <button
              onClick={handleOpenExportModal}
              className="bg-primaryGreen py-2 px-4  text-black font-semibold rounded-lg  hover:bg-secondaryGreen"
            >
              Exportar encuestas
            </button>
          </div>

          <div className='text-green-500 text-sm font-normal py-3'>
            {successMessage}
          </div>
          <div className='text-red-500 text-sm font-normal py-3'>
            {errorMessage}
          </div>
          <div className="text-sm overflow-x-auto desktopL:w-full w-full ">
            <table className="min-w-full bg-white border-separate border border-spacing-x-2 border-transparent">
              <thead className="bg-secondaryGray">
                <tr>
                  {columns.map((column) => (
                    <th key={column.id} className="text-center px-4 py-2 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg">
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {employeesList.length > 0 ? (
                  employeesList.map((employee) => (
                    <tr key={employee.id} className="hover:bg-gray-100">
                      <td className="px-2 py-4 whitespace-nowrap">
                        {employee.first_name} {employee.last_name}
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.id_card}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.phone}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.email}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.role_es}</td>
                      <td className="px-1 py-4 whitespace-nowrap">{employee.status}</td>
                      <td className="px-1 py-4 whitespace-nowrap text-center">
                        <button
                          onClick={() => handleStatusChange(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                        >
                          {employee.status === 'Activo' ? 'Desactivar' : 'Activar'}
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center">
                        <button
                          onClick={() => handleSurveyClick(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                          disabled={!(employee.status === 'Activo')}
                        >
                          Encuesta
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center">
                        <button onClick={() => handleEvidenceClick(employee.id)}>
                          <img src={evidenceIcon} alt="Logo evidencias" />
                        </button>
                      </td>
                      <td className="px-1 py-4 whitespace-nowrap text-center"> {/* Nueva columna */}
                        <button
                          onClick={() => handleEditEmployee(employee)}
                          className="text-secondaryOrange py-1 px-2 rounded"
                        >
                          Editar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-4 text-center text-gray-500">
                      No hay Empleados.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between gap-5 mt-4">
            <button
              className="p-2 border border-gray-300 rounded bg-gray-200"
              color="primary"
              disabled={!previous}
              onClick={() => fetchEmployees(previous)}
            >
              Anterior
            </button>
            <button
              className="p-2 border border-gray-300 rounded bg-gray-200"
              color="primary"
              disabled={!next}
              onClick={() => fetchEmployees(next)}
            >
              Siguiente
            </button>
          </div>
          <br />
        </div>
      </MainLayout>

      <CreateEmployeeModal title='Crear' open={showModal} onClose={handleCloseModal} onCreateOrUpdate={handleEmployeeCreate} />

      <Modal open={showCreateEvidenceModal} onClose={handleCloseEvidenceModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxWidth: '1000px',
            minHeight: '350px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
          }}
        >
          <div className='flex flex-col justify-around gap-4'>
            {evidenceType !== 'work' && (
              <div>
                <div className="text-center mb-8 font-bold text-xxl">
                  Elija el tipo de evidencia
                </div>
                <div className='flex justify-around gap-4'>
                  <Button
                    onClick={() => handleEvidenceTypeClick('work')}
                    text='Puestos de trabajo'
                  />
                  <span></span>
                  <div className='grid'>
                    <Button
                      onClick={() => handleEvidenceTypeClick('improvement')}
                      text='Planes de mejora'
                    />

                    <span className='text-sm text-red-500'>{errorMessagePlans}</span>
                  </div>
                </div>
              </div>
            )}
            {evidenceType === 'work' && selectedEmployeeId &&
              <div>
                <div className="text-center mb-8 font-bold text-xxl"> Añade el puesto de trabajo</div>
                <AddImages employeeId={selectedEmployeeId} />
              </div>
            }
            <div className='flex justify-center text-center gap-4 mt-10'>
              <CancelButton
                onClick={handleCloseEvidenceModal}
                text='Cerrar'
              />
            </div>
          </div>
        </Box>
      </Modal>

      <EvidenceModal open={showEvidenceModal} onClose={handleCloseEvidenceModal} evidence={workEnv} />
      <ImprovementPlansPerEmployeeModal open={showImprovementPlansModal} onClose={handleCloseImprovementPlansModal} improvementPlans={improvementPlansPerEmployee} employeeId={selectedEmployeeId} />

      <Modal open={showExportModal} onClose={handleCloseExportModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxWidth: '600px',
            maxHeight: '90vh',
            minHeight: '200px',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            overflowY: 'auto',
          }}
        >
          <div className='flex flex-col justify-around gap-4'>
            <div className="text-center mb-8 font-bold text-xxl">
              Elija la encuesta que va a exportar y que año
            </div>
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Seleccionar Año</InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value as number)}
                label="Seleccionar Año"
                color='error'
                className='text-red-500'
              >
                {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className='flex flex-col gap-4'>
              <Button
                onClick={() => handleExportOptionClick('matrix')}
                text='Exportar Matriz'
              />
              {missingData?.missing_matriz_employees && missingData.missing_matriz_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-center'>
                    Faltan {missingData.missing_matriz_employees.length} empleados por realizar esta encuesta
                  </span>
                  {renderMissingEmployees(missingData.missing_matriz_employees)}
                </>
              )}
              <Button
                onClick={() => handleExportOptionClick('healthConditions')}
                text='Exportar Condiciones de Salud'
              />
              {missingData?.missing_health_conditions_employees && missingData.missing_health_conditions_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-center'>
                    Faltan {missingData.missing_health_conditions_employees.length} empleados por realizar esta encuesta.
                  </span>
                  {renderMissingEmployees(missingData.missing_health_conditions_employees)}
                </>
              )}
              <Button
                onClick={() => handleExportOptionClick('workConditions')}
                text='Exportar Condiciones de trabajo'
              />
              {missingData?.missing_work_conditions_employees && missingData.missing_work_conditions_employees.length > 0 && (
                <>
                  <span className='text-red-500 text-sm text-center'>
                    Faltan {missingData.missing_work_conditions_employees?.length} empleados por realizar esta encuesta.
                  </span>
                  {renderMissingEmployees(missingData.missing_work_conditions_employees)}
                </>
              )}
              <span className='text-lg text-red-500 mt-3 text-center'>{errorMessageSurvey}</span>
            </div>
            <div className='flex justify-center text-center gap-4 mt-4'>
              <CancelButton
                onClick={handleCloseExportModal}
                text='Cerrar'
              />
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={showSurveyModal} onClose={handleCloseSurveyModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxWidth: '600px',
            minHeight: '200px',
            bgcolor: 'background.paper',
            border: '2px solid #704104',
            boxShadow: 24,
            borderRadius: '10px'
          }}
        >
          <div className='flex flex-col justify-around gap-4 '>
            <div className="flex justify-center text-center items-center h-1/4 bg-gradient-to-b from-primaryOrange to-white rounded-lg">
              <div className="flex justify-center items-center text-center p-4">
                <div className=''>
                  <h1 className="mobile:text-xl text-2xl desktop:text-4xl desktopL:text-3xl laptop:text-3xl items-center font-rounded font-semibold text-white">
                    Reenvio de encuestas
                  </h1>
                  <div className="flex text-xl justify-center text-secondaryBrown mt-2">
                    <span>Seleccione la encuesta a reenviar</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center flex-col gap-4 py-5'>
              <Button
                onClick={() => handleOptionSuvey('matriz_sociodemographic')}
                text='Matriz sociodemográfica'
              />

              <Button
                onClick={() => handleOptionSuvey('health_conditions')}
                text='Condiciones de salud'
              />
              <Button
                onClick={() => handleOptionSuvey('work_conditions')}
                text='Condiciones de trabajo'
              />

              <span className='text-lg text-red-500 mt-3 text-center'>{errorMessage}</span>
              <span className='text-lg text-primaryBrown mt-3 text-center'>{successMessage}</span>
            </div>
            <div className='flex justify-center text-center gap-4 my-5'>
              <CancelButton
                onClick={handleCloseSurveyModal}
                text='Cerrar'
              />
            </div>
          </div>
        </Box>
      </Modal>  
      <CreateEmployeeModal
        open={showEditModal}
        title='Editar'
        onClose={handleCloseEditModal}
        onCreateOrUpdate={handleEmployeeUpdate}
        employee={employee}
      />

    </>
  );
};

export default ModuleEmployees;
