import { ReactElement, useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
    children: ReactElement<any, any> | null;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const isAuthenticated = useAppSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);
    return children;
};

export default PrivateRoute;
