import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || '';

interface StartPaymentResponse {
    public_key: string;
    signature: string;
    reference: string;
    currency: string;
    amount_in_cents: number;
}

interface PaymentResult {
    email: string;
    name: string;
    phone: string;
    reference: string;
    status: string;
    amount: number;
    transaction_id: string;
}

export class PaymentAttemptRequest {
    plan: number;
    email: string;
    company_name: string;
    phone: string;

    constructor(planId: number, email: string, companyName: string, phone: string) {
        this.plan = planId;
        this.email = email;
        this.company_name = companyName;
        this.phone = phone;
    }
}

const createPaymentAttempt = (
    request: PaymentAttemptRequest,
    onSuccess: (response: StartPaymentResponse) => void,
    onError: (error: any) => void
): void => {
    axios.post<StartPaymentResponse>(`${API_URL}/api/payments/attempt`, request)
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};

const getPaymentMetadata = (

    token: string,
    onSuccess: (response: StartPaymentResponse) => void,
    onError: (error: any) => void
): void => {
    axios.get<StartPaymentResponse>(`${API_URL}/api/payments/start`, {
        params: {
            token: token
        }
    })
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};

const getPaymentResult = (
    transactionId: string,
    onSuccess: (response: PaymentResult) => void,
    onError: (error: any) => void
): void => {
    axios.get<PaymentResult>(`${API_URL}/api/payments/result?transaction_id=${transactionId}`)
        .then(response => onSuccess(response.data))
        .catch(error => onError(error))
};

export const paymentService = {
    getPaymentMetadata,
    getPaymentResult,
    createPaymentAttempt
}
