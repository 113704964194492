import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const CancelButton: React.FC<ButtonProps> = ({ text, onClick, disabled = false }) => (
  <button
    className={`bg-primaryRed w-auto px-4 h-8 text-white font-semibold rounded-md hover:bg-secondaryRed ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);

export default CancelButton;
