import React from 'react';

interface TextFieldDateProps {
  label: string;
  value: Date | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
}

const TextFieldDate: React.FC<TextFieldDateProps> = ({ label, value, onChange, title }) => {
  const formattedValue = value ? value.toISOString().split('T')[0] : '';

  return (
    <div className="flex flex-col mb-4 w-full">
      <label className="mb-1 text-lg">{label}</label>
      <input
        title={title}
        type="date"
        value={formattedValue}
        onChange={onChange}
        className="w-full py-2 px-3 border-none rounded bg-gray-200 shadow-inner text-sm min-w-[10rem]"
      />
    </div>
  );
};

export default TextFieldDate;
