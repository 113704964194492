export const absenceTypes = [
    "Accidente laboral",
    "Enfermedad general",
    "Enfermedad profesional",
    "Faltas sin Justificar",
    "Calamidad Domestica",
    "Licencia No Remunerada",
    "Licencia Remunerada",
    "Licencia de Maternidad",
    "Licencia de Paternidad",
    "Licencia Luto",
    "Permiso Sindical"
];
