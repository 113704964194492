import { Card, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { submitInspection, InspectionData } from '../../services/initialInspection';
import SuccessModal from "../SuccessModal";
import HeaderSurveys from '../../components/HeaderSurveys';

const initialState = {
  clean_and_orderly_conditions: '',
  clean_and_orderly_conditions_notes: '',
  equipment_layout_safety: '',
  equipment_layout_safety_notes: '',
  sufficient_space: '',
  sufficient_space_notes: '',
  adequate_evacuation_routes: '',
  adequate_evacuation_routes_notes: '',
  hazard_warning_signage: '',
  hazard_warning_signage_notes: '',
  dangerous_zones_isolated: '',
  dangerous_zones_isolated_notes: '',
  first_aid_equipment: '',
  first_aid_equipment_notes: '',
  electrical_installations_condition: '',
  electrical_installations_condition_notes: '',
  machine_moving_parts_guarded: '',
  machine_moving_parts_guarded_notes: '',
  appropriate_tools_maintenance: '',
  appropriate_tools_maintenance_notes: '',
  msds_available_in_spanish: '',
  msds_available_in_spanish_notes: '',
  chemicals_properly_labelled: '',
  chemicals_properly_labelled_notes: '',
  risk_prevention_training: '',
  risk_prevention_training_notes: '',
  personal_protection_equipment_available: '',
  personal_protection_equipment_available_notes: '',
  ppe_delivery_records: '',
  ppe_delivery_records_notes: '',
  emergency_plan_written: '',
  emergency_plan_written_notes: '',
  safe_procedure_compliance: '',
  safe_procedure_compliance_notes: '',
  hazardous_conditions_reported: '',
  hazardous_conditions_reported_notes: '',
  incidents_and_accidents_reported: '',
  incidents_and_accidents_reported_notes: '',
};

export function InitialInspection() {
  const [formData, setFormData] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    let newValue: any;
    if (type === 'checkbox') {
      newValue = checked;
    } else if (type === 'radio') {
      newValue = value === 'yes'
    } else {
      newValue = value;
    }

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  const handleSubmit = () => {
    const inspectionData = new InspectionData(formData);
    submitInspection(inspectionData,
      (response) => {
        setShowModal(true)
      },
      (error) => {
        console.error('Error al enviar inspección:', error);
        setErrorMessage("Error al enviar inspección inicial, verifique que todos los datos esten completos")
      }
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <SuccessModal
          message="Inspección inicial enviado con éxito"
          buttonText="Continuar"
          onClose={handleCloseModal}
          redirectPath="/autoevaluation"
        />
      )}
      <Card placeholder='' className="grid place-content-center place-items-center w-auto bg-[url('/src/assets/backgrounds/login-background.svg')] bg-repeat-y bg-center items-center p-4" color="transparent" shadow={true}>

        <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg border-2 border-secondaryBrown" >
          <HeaderSurveys title={`Inspección inicial`} />
          <div className="flex flex-col gap-6 mt-4">
            {questions.map((question, index) => (
              <div key={index} className="p-4">
                <Typography placeholder='' variant="h6" color="blue-gray">
                  {question.text}
                </Typography>
                <div className="flex justify-around my-2">
                  <label className="flex items-center gap-2">
                    <input type="radio" name={question.propName} value="yes" onChange={handleChange} />
                    Sí
                  </label>
                  <label className="flex items-center gap-2">
                    <input type="radio" name={question.propName} value="no" onChange={handleChange} />
                    No
                  </label>
                </div>
                <input type='text' name={`${question.propName}_notes`} placeholder="Observaciones" className="mt-1 w-full text-base p-3 drop-shadow-lg" onChange={handleChange} />
              </div>
            ))}
          </div>
          <div className="text-center text-red-500 text-xl py-4">
            {errorMessage && <p> {errorMessage}</p>}

          </div>
          <div className="flex p-4 place-content-center">
            <button type="button" onClick={handleSubmit} className="bg-primaryGreen text-sm rounded-lg mobile:min-w-32 px-4 h-8 text-black font-semibold hover:bg-secondaryGreen">
              Enviar encuesta
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

const questions = [
  { text: "¿Existen buenas condiciones de orden y limpieza?", propName: "clean_and_orderly_conditions" },
  { text: "¿La distribución de equipos, máquinas, insumos, etc. facilita la seguridad del personal?", propName: "equipment_layout_safety" },
  { text: "¿Los trabajadores tienen el espacio suficiente y adecuado para realizar eficientemente el trabajo? (Alturas, accesos, etc.)", propName: "sufficient_space" },
  { text: "¿Se cuenta con rutas de evacuación y de salida de emergencia suficientes, despejadas y señalizadas?", propName: "adequate_evacuation_routes" },
  { text: "¿Se cuenta con señalización para máquinas, áreas, secciones, etc., dónde se generen advertencias de peligros?", propName: "hazard_warning_signage" },
  { text: "¿Se aíslan y demarcan las zonas peligrosas? Por ejemplo, con químicos, máquinas, electricidad, etc. para evitar la presencia de personal no autorizado.", propName: "dangerous_zones_isolated" },
  { text: "¿Se cuenta con equipos de primeros auxilios, tales como botiquín, camillas y frazadas, etc.?", propName: "first_aid_equipment" },
  { text: "¿En la empresa se encuentran las instalaciones eléctricas en buen estado y señalizadas?", propName: "electrical_installations_condition" },
  { text: "¿Las partes móviles de las maquinas (Poleas, cadenas, motores, etc.) están adecuadamente protegidos?", propName: "machine_moving_parts_guarded" },
  { text: "¿Las herramientas son las adecuadas de acuerdo al proceso de la empresa, se usan para tal fin, ¿están en buen estado y se les realiza mantenimiento?", propName: "appropriate_tools_maintenance" },
  { text: "¿Se cuenta con las Hojas de Seguridad de los productos químicos utilizados y están disponibles para el personal y en español?", propName: "msds_available_in_spanish" },
  { text: "¿Los productos químicos se encuentran adecuadamente etiquetados y empacados?", propName: "chemicals_properly_labelled" },
  { text: "¿Se ha capacitado al personal en prevención de riesgos y existen evidencias?", propName: "risk_prevention_training" },
  { text: "¿El personal cuenta con los Elementos de Protección Personal necesarios para desarrollar sus labores?", propName: "personal_protection_equipment_available" },
  { text: "¿Se tienen los registros de entrega de los Elementos de Protección Personal que se han suministrado a los trabajadores?", propName: "ppe_delivery_records" },
  { text: "¿La empresa cuenta con un Plan de Emergencias por escrito y se lleva a cabo?", propName: "emergency_plan_written" },
  { text: "¿Se cuenta con procedimientos para la realización de forma segura de las diferentes tareas?", propName: "safe_procedure_compliance" },
  { text: "¿El personal reporta las condiciones peligrosas?", propName: "hazardous_conditions_reported" },
  { text: "¿El personal reporta incidentes y accidentes de trabajo?", propName: "incidents_and_accidents_reported" },
];
