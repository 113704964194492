import React from 'react';

export interface Option {
  value: string; // El valor de la opción
  label: string; // El texto que se mostrará
  disabled?: boolean; // Opcional, para desactivar opciones
}

interface SelectInputProps {
  id: string;
  label: string;
  value?: string; 
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void; 
  options: Option[]; 
  title?: string; 
}

const SelectInput: React.FC<SelectInputProps> = ({ id, label, value, onChange, options, title }) => {


  return (
    <div className="w-full flex flex-col mb-4">
      <label htmlFor={id} className="mb-1 text-xl">
        {label}
      </label>
      <select
        id={id}
        title={title}
        value={value} // El valor seleccionado
        onChange={onChange} // Función que maneja el cambio
        className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktopL:min-w-[10rem] w-full mobile:w-full"
      >
        <option value="" disabled>
          Selecciona
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value} // El valor de la opción
            disabled={option.disabled} // Si la opción está desactivada
          >
            {option.label} 
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
