import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean; 
}

const Button: React.FC<ButtonProps> = ({ text, onClick, disabled = false }) => (
  <button
    className={`bg-primaryGreen text-sm rounded-lg w-auto mobile:min-w-32 px-4 h-8 text-black font-semibold hover:bg-secondaryGreen 
      ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} 
    onClick={disabled ? undefined : onClick} 
    disabled={disabled} 
  >
    {text}
  </button>
);

export default Button;
