
import React from 'react';

interface PaymentProps {
  amount: number;
  name: string;
  status: string;
  reference: string;
  email: string;
}

const PaymentPrint: React.FC<PaymentProps> = ({ amount, name, status, reference, email }) => {
  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold">Detalle del Pago</h2>
      <div>Estado: <strong>{status}</strong></div>
      <div>Name: <strong>${name}</strong></div>
      <div>Email: <strong>{email}</strong></div>
      <div>Referencia: <strong>{reference}</strong></div>
      <div>Amount: <strong>{amount}</strong></div>
    </div>
  );
};

export default PaymentPrint;