import listWorksData from './listWorks.json'; // Ajusta la ruta según sea necesario

export const handleInputValueChange = (
  value: string,
  setJobOptions: React.Dispatch<React.SetStateAction<Array<{ value: string; label: string }>>>
) => {
  if (value.length >= 5) {
    const filteredJobs = listWorksData.filter(job =>
      job.job.toLowerCase().includes(value.toLowerCase())
    ).map(job => ({
      value: job.Code.toString(),
      label: job.job,
    }));
    setJobOptions(filteredJobs);
  } else {
    setJobOptions([]);
  }
};