// ConfirmationModal.tsx
import React from 'react';
import Button from './Button';
import CancelButton from './CancelButton';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationDeleteModal: React.FC<ConfirmationModalProps> = ({ open, onClose, onConfirm, message }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-4">
        <div className="text-lg mb-4">{message}</div>
        <div className="flex justify-center gap-4">
          <Button text="Confirmar" onClick={onConfirm} />
          <CancelButton text="Cancelar" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteModal;
