import React from 'react';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

interface SelectInputStringProps {
  id: string;
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
  options: Option[];
  title?: string;
}

const SelectInputString: React.FC<SelectInputStringProps> = ({ id, label, value, onChange, options, title }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value );
  };

  return (
    <div className="flex flex-col mb-4">
      <label htmlFor={id} className="mb-1 text-xl">
        {label}
      </label>
      <select
        id={id}
        title={title}
        value={value === undefined ? '' : value} 
        onChange={handleChange}
        className="border border-gray-300 bg-gray-200 rounded-md py-2 px-3 text-sm desktop:min-w-[10rem] desktop:max-w-[12rem]  desktopL:min-w-[10rem] desktopL:max-w-[12rem]  mobile:w-full"
      >
        <option value="" disabled>
          Selecciona
        </option>
        {options.map((option) => (
          <option
            key={option.value.toString()} 
            value={option.value.toString()} 
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInputString;
