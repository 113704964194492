import { useNavigate } from 'react-router-dom';
import { Plan } from '../../services/plansService';
import Button from '../Button';

interface PriceOptionProps {
  plan: Plan;
}

const PlansCard = (props: PriceOptionProps) => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate(`/plans/${props.plan.id}`)
  };

  return (
      <>
        <div className="w-80 flex flex-col items-center">
          <div className="w-full flex flex-col items-center">
            <div className="bg-gradient-to-b from-tertiaryYellow to-secondaryOrange text-white text-center w-full py-2 rounded-t-lg">
              {props.plan.id === 1 && <h2 className="text-lg font-bold">1 a 5</h2>}
              {props.plan.id === 2 && <h2 className="text-lg font-bold">6 a 10</h2>}
              {props.plan.id === 3 && <h2 className="text-lg font-bold">11 a 15</h2>}
            </div>
            <div className={`${props.plan.id === 2 ? 'bg-[#e9e9e9]' : 'bg-white'} w-full shadow-md border-secondaryBrown border-2 border-t-0 text-secondaryBrown relative p-4`}>
              <div className="text-center pt-4">
                <div className="text-4xl font-bold my-4">
                  <span className="align-middle text-lg">$</span>
                  {props.plan.price.toLocaleString('es-ES')}
                  <span className="text-lg">/mes</span>
                </div>
                <div className="text-secondaryBrown my-4 space-y-2 text-sm text-center">
                  <div>8/5 Soporte</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>2 Usuarios / empresa</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Inspecciones</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Encuestas</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Gestión Documental</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Autoevaluaciones</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Indicadores básicos</div>
                  <hr className="my-2 border-secondaryBrown border-t-1 mx-auto max-w-[70%]" />
                  <div>Elemento de lista</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-4 w-full">
            <Button
              text="Comprar"
              onClick={handleButtonClick}
            />
          </div>
        </div>
      </>
  );
};
export default PlansCard