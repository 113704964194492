import React from 'react';
import ReactDOM from 'react-dom';
import PaymentPrint from './PaymentPrint'; // Asegúrate de que el path sea correcto

interface PaymentProps {
  amount: number;
  name: string;
  status: string;
  reference: string;
  email: string;
}

export const printPayment = (props: PaymentProps) => {
  // Intenta abrir una nueva ventana
  const printWindow = window.open('', '_blank', 'height=600,width=800');

  // Verifica si la ventana se abrió correctamente
  if (printWindow) {
    printWindow.document.write('<html><head><title>Imprimir Detalle del Pago</title></head><body>');
    printWindow.document.write('</body></html>');

    const container = printWindow.document.createElement('div');
    printWindow.document.body.appendChild(container);

    ReactDOM.render(<PaymentPrint {...props} />, container, () => {
      printWindow.print();
      printWindow.close();
    });
  } else {
    alert("No se pudo abrir la ventana de impresión. Por favor, permite las ventanas emergentes para este sitio.");
  }
};