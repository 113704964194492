import { useEffect, useState } from 'react'
import { AutoevaluationDataResponse, ImprovementPlansData, autoevaluationService } from '../../services/autoevaluationService';
import { useNavigate } from 'react-router-dom';
import { companyService } from '../../services/companyServices'
import { customizedItems } from '../../utils/customizedItems';
import { EvidenceImprovementPlanDataResponse, GetEvidenceImprovementPlansByEmployee, uploadImageService } from '../../services/uploadImageServices';
import EvidencePlansModal from '../../components/improvementPlan/EvidencePlansModal';
import MyCalendar from '../../components/MyCalendar';
import { backupService } from '../../services/backupService';
import BottomArrow from '../../assets/icons/bottom-arrow.svg'
import workAccidentIcon from '../../assets/icons/work-accident-icon.svg'
import CircularProgress from '../../components/CircularProgress';
import evidenceIcon from '../../assets/icons/evidence-icon.svg'

import ItemTextNumber from '../../components/ItemTextNumber';
import TableItems, { EvidenceDetails } from '../../components/TableItems';
import TableItemsExtras from '../../components/TableItemsExtras';
import Button from '../../components/Button';
import { FaInfoCircle } from 'react-icons/fa';
import MainLayout from '../../components/MainLayout';

type Props = {}

const columns = [
  { id: 'item', label: 'Item' },
  { id: 'updatedDate', label: 'Fecha de actualización' },
  { id: 'validity', label: 'Vigencia' },
  { id: 'state', label: 'Estado' },
  { id: 'evidences', label: 'Evidencias' },
];

const News = [
  'Nueva Ley laboral',
  'Cotización 2025',
  'Cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  'Más cambios en ARL',
  // Agrega más noticias aquí si es necesario
];
type Row = {
  item: string;
  updatedDate: string;
  validity: string;
  state: string;
  evidences: string;
};

const getItemTextCustom = (itemName?: string): string => {
  const item = customizedItems.find(i => i.propName === itemName);
  return item ? item.text : itemName ?? 'Unknown Item'; // Provide a fallback if itemName is undefined
};

const Home = (props: Props) => {
  const [formData, setFormData] = useState<AutoevaluationDataResponse>();
  const [riskLevel, setRiskLevel] = useState<string>('')
  const [numEmployees, setNumEmployees] = useState<number>(0)
  const [percentageWork, setPercentageWork] = useState<number>(0)
  const [percentageTraining, setPercentageTraining] = useState<number>(0)
  const [customizedPlans, setCustomizedPlans] = useState<ImprovementPlansData[]>([])
  const [filteredPlans, setFilteredPlans] = useState<Row[]>([]);
  const [plans, setPlans] = useState<ImprovementPlansData[]>([])
  const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [evidenceDetails, setEvidenceDetails] = useState<EvidenceDetails[] | null>(null);
  const [backupId, setBackupId] = useState<string>()
  const [backupStatus, setBackupStatus] = useState<string>(''); // Estado para manejar el estado del backup
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [errorBackup, setErrorBackup] = useState<string | undefined>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {

    let intervalId: NodeJS.Timeout | null = null;

    if (backupId) {
      intervalId = setInterval(() => {
        backupService.checkBackupStatus(
          backupId,
          (data) => {
            setBackupStatus(data.status);
            if (data.status === "Error") {
              setErrorBackup(data.error)
              clearInterval(intervalId!);
            } else if (data.status === "Creado" && data.download_url) {
              setDownloadUrl(data.download_url);
              clearInterval(intervalId!);
            }
          },
          (error) => {
            console.error('Error checking backup status:', error);
            setBackupStatus('failed');
            clearInterval(intervalId!);
          }
        );
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Limpiar intervalo al desmontar el componente o cambiar el backupId
      }
    };
  }, [backupId]);


  const getMinimumStandards = (numberEmployees: number) => {
    if (numberEmployees < 11) {
      return 7;
    } else if (numberEmployees >= 11 && numberEmployees < 50) {
      return 21;
    } else {
      return 60;
    }
  };

  const handleAutoevaluationPdf = () => {
    autoevaluationService.autoevaluationPdf(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'autoevaluation.pdf'); // Nombre del archivo a descargar
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );
  };


  useEffect(() => {
    autoevaluationService.getAutoevaluation(
      (response) => {
        console.log(response);
        setFormData(response)
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }

    );
    companyService.getCompanyData(
      (response) => {
        console.log('Get company:', response.number_of_employees);
        setRiskLevel(response.risk_level)
        setNumEmployees(response.number_of_employees)

      },
      (error) => {
        console.error('Error al buscar la company:', error);
      },

    )
    const currentYear = new Date().getFullYear();

    autoevaluationService.getImprovementPlansPercentage(
      currentYear,
      (response) => {
        console.log(response);
        setPercentageWork(response.work_plan__percentage)
        setPercentageTraining(response.training_plan__percentage)
        setCustomizedPlans(response.customized_plans)
        setPlans(response.improvement_plans_list)

      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      }
    );

  }, [])



  const handleOpenEvidenceModal = (itemName: string) => {
    const item = customizedItems.find(i => i.propName === itemName);
    setSelectedItem(item?.text);
    
    if (item) {
      uploadImageService.getEvidenceImprovementPlans(
        new GetEvidenceImprovementPlansByEmployee(item.propName),
        (response: EvidenceImprovementPlanDataResponse[]) => {
          setEvidenceDetails(response);
          setEvidenceModalOpen(true);
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
        }
      );
    }
  };

  const handleCloseEvidenceModal = () => {
    setEvidenceModalOpen(false);
    setSelectedItem(undefined);
    setEvidenceDetails(null);
  };

  const handleBackup = () => {
    backupService.downloadBackup(
      (data) => {
        setBackupId(data.backup_id)
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when rows per page changes
  };

  const getFilteredPlans = (plans: ImprovementPlansData[]): Row[] => {
    return plans.map(plan => {
      const currentYear = new Date().getFullYear();
      const endOfYear = new Date(currentYear, 11, 31); // End of the year

      const createdAtDate = plan.created_at ? new Date(plan.created_at) : new Date();
      const validity = Math.max(0, Math.ceil((endOfYear.getTime() - createdAtDate.getTime()) / (1000 * 60 * 60 * 24)));

      return {
        item: getItemTextCustom(plan.customized_item_name),
        updatedDate: createdAtDate.toLocaleDateString(),
        validity: `${validity} días`,
        state: plan.state || 'No definido', // Proporciona un valor predeterminado
        evidences: plan.customized_item_name || 'No disponible', // Proporciona un valor predeterminado
      };
    });
  };
  useEffect(() => {
    setFilteredPlans(getFilteredPlans(customizedPlans));
  }, [plans]);

  return (
    <>
      <MainLayout>
        <div className='flex justify-between my-2 gap-7'>
          <div className='flex justify-start space-x-6'>
            <CircularProgress
              percentage={percentageWork}
              text='Plan de trabajo' />
            <CircularProgress
              percentage={percentageTraining}
              text='Plan de Capacitación' />
            <CircularProgress
              percentage={formData?.total_points ? formData.total_points : 0}
              text='Autoevaluación' />
          </div>
          <div className='place-content-center pb-4'>
            <button
              className='bg-secundaryYellow text-primaryBrown font-bold text-xl p-2 rounded-lg flex items-center py-1  px-10 mr-48 desktopL:mr-96 desktop:mr-70 laptop:mr-72 tablet:mr-60'
              onClick={() => setShowCalendar(!showCalendar)}
            > Calendario <img className='ml-6' src={BottomArrow} alt="Flecha abajo" /></button>
            {showCalendar && (
              <MyCalendar plans={plans} />
            )}

          </div>
        </div>
        <div className="flex flex-1">

          <div className="flex-grow p-4">
            <main className="grid top-0  right-0 max-w-full">
              <div className='grid grid-cols-1 desktopL:grid-cols-3 desktop:grid-cols-2 right-0 gap-9 pb-10'>
                <div className="grid h-full w-full p-4 rounded-lg border-secondaryOrange border-2" >
                  <ItemTextNumber text='Estandares mínimos' number={getMinimumStandards(numEmployees).toString()} />
                  <ItemTextNumber text='Numero de trabajadores' number={numEmployees.toString()} />
                  <ItemTextNumber text='Nivel de riesgo' number={riskLevel} />
                  <div className="flex flex-col gap-3 mt-4">
                    <Button
                      onClick={() => navigate('/autoevaluation')}
                      text='Crear autoevaluación'
                    />
                    <Button
                      onClick={handleAutoevaluationPdf}
                      text='Descargar Autoevaluación'
                    />
                  </div>
                </div>
                <div className="grid grid-rows-3 grid-cols-3 h-full w-full p-4 border-secondaryOrange border-2 rounded-lg" >
                  <div className='row-span-1 col-span-3 px-20 text-center text-lg text-textOrange'>
                    Reporte de accidentalidad <strong>agosto</strong>
                  </div>
                  <div className='grid grid-rows-2 grid-cols-3 row-span-2 col-span-3 text-center text-sm'>
                    <div className='grid row-span-1 col-span-1 '>
                      <p>Enfermedad común</p>
                      <span className='text-xxl font-semibold'>0</span>
                    </div>
                    <div className='flex flex-col items-center justify-center row-span-2 col-span-1'>
                      <img src={workAccidentIcon} alt="" />
                      <p>Accidentes de trabajo</p>
                      <span className='text-xxl font-semibold'>0</span>
                    </div>
                    <div className='row-span-1 col-span-1'>
                      <p>Enfermedad laboral</p>
                      <span className='text-xxl font-semibold'>0</span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-rows-[auto,1fr] gap-4 text-center p-4  border-secondaryOrange border-2 h-full w-auto min-w-96 sm:w-auto max-h-80 rounded-lg">
                  <div className="row-span-1 bg-secundaryYellow text-primaryBrown font-bold text-xl p-2 rounded-lg">
                    Noticias Laborus
                  </div>
                  <div className="row-span-4 scroll-container space-y-4">
                    {News.map((newsItem, index) => (
                      <div key={index} className="bg-gray-200 text-primaryBrown text-lg p-2 rounded-lg">
                        {newsItem}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex mb-4'>
                <div className="text-xl font-semibold text-secondaryOrange">Items estándares</div>
                <div className="ml-2 relative flex items-center group">
                  <FaInfoCircle className="text-secondaryOrange cursor-pointer" />
                  <div className="absolute top-full left-0 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-primaryGray text-white text-xs rounded p-2 z-50 w-64">
                    Ítems relacionados con estándares legales a cumplir
                  </div>
                  <div className='flex justify-center items-center mx-4'>
                    <Button
                      onClick={handleBackup}
                      text='Descargar Backup'
                    />
                    {backupStatus && (
                      <div className='flex text-center px-6 py-4 gap-2 items-center justify-center'>
                        <div className='text-lg text-secondaryBrown'>
                          {backupStatus === 'Error' ? (
                            <div>
                              La creación del backup falló.
                            </div>
                          ) : (
                            <span>El backup está {backupStatus}</span>
                          )}
                        </div>
                        {backupStatus === 'Creado' && downloadUrl && (
                          <a href={downloadUrl} download="backup.zip">
                            <button className='text-primaryOrange bg-transparent border-none p-0 m-0 cursor-pointer'>Descargar Backup</button>
                          </a>
                        )}
                        {backupStatus === 'Error' && errorBackup && (
                          <div className='text-sm text-primaryRed'>
                            {errorBackup}
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>
              </div>

              <div className='w-full '>
                {formData !== undefined ? (
                  <TableItems
                    formData={formData}
                    title="Items estándares"
                  />
                ) : (
                  <p>Cargando datos...</p>
                )}
              </div>
              <div className='w-full my-10'>
                <TableItemsExtras
                  title="Items extras"
                  columns={columns}
                  rows={filteredPlans}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleOpenModal={handleOpenEvidenceModal}
                  evidenceIcon={evidenceIcon}
                />
              </div>
              <EvidencePlansModal
                open={evidenceModalOpen}
                onClose={handleCloseEvidenceModal}
                details={evidenceDetails}
                item={selectedItem}
              />
            </main>
          </div>
        </div>
      </MainLayout>
    </>
  )
}
export default Home