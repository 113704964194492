import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || ''; 

interface UserResponsePassword {
    token: string;
    user: any;
}

export class NewPasswordData {
    token:string | undefined;
    password :string;
    confirm_password :string;

  constructor(
        token:string | undefined,
        password: string, 
        confirm_password: string, 
        
    ) {
    this.token = token;
    this.password = password; 
    this.confirm_password = confirm_password; 
  }
}

type OnSuccess = (response: UserResponsePassword) => void;

type OnError = (error: any) => void;

const createNewPassword = (newPasswordData: NewPasswordData, onSuccess: OnSuccess, onError: OnError): void => {
   axios.post<UserResponsePassword>(`${API_URL}/api/auth/password/update`, newPasswordData)
    .then(response => onSuccess(response.data)
      )
    .catch(error => onError(error)
    )
};

export const userUpdatePasswordService = {
    createNewPassword,
};