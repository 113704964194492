import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ListEmployees from "../../components/uploadImagesAndVideo/ListEmployees";
import AddVideos from "../../components/uploadImagesAndVideo/AddVideos";
import { InitialInspection } from "../../components/inspection/InitialInspection";

export function TabsCustomAnimation() {
  const data = [
    {
      label: "Imágenes de empleados",
      value: "employeeImage",
      desc: (
        <div>
          <ListEmployees />
        </div>
      ),
    },
    {
      label: "Video de la empresa",
      value: "companyVideo",
      desc: (
        <div>
          <AddVideos />
        </div>
      ),
    },

    {
      label: "Inspección Inicial",
      value: "inspection",
      desc: (
        <div>
          <InitialInspection />
        </div>
      ),
    },


  ];

  return (
    <Tabs id="custom-animation" value="employeeImage">
      <TabsHeader className="bg-gray-100  " placeholder={''}>
        {data.map(({ label, value }) => (
          <Tab className="cursor-pointer" placeholder={''} key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="backgroundWelcome bg-cover" placeholder={''}
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
      >
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}