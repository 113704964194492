import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CompanyEvidenceData, EvidenceRequest, EvidenceResponse, UploadRequest, uploadImageService } from "../../services/uploadImageServices";
import { useParams } from "react-router-dom";
import SuccessModal from "../SuccessModal";

interface AddVideosProps {

}

const AddVideos = (props: AddVideosProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  let { id } = useParams<{ id?: string }>();


  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/*': ['.mp4', '.mov', '.wmv', '.avi'] // Acepta formatos de video comunes
    }
  });

  const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {
    return new Promise<EvidenceResponse>((resolve, reject) => {
      uploadImageService.generateUrl(request, (response: EvidenceResponse) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      },
      );
    });
  };

  const uploadAndRegisterVideoPromise = (file: File, url: string, finalUrl: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await uploadImageService.uploadImage(new UploadRequest(file, url),
          response => {
            console.log('Upload success:', response);
            uploadImageService.companyEvidence(new CompanyEvidenceData(finalUrl),
              () => {
                setShowModal(true)
                resolve();
              }, (error) => {
                setErrorMessage("No se pudo subir el video")
                console.error('CompanyEvidence error:', error);
                reject(error);
              });
          }, (error) => {
            console.error('Upload error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error processing file:', error);
        reject(error);
      }
    });
  };

  const handleClick = async (event: React.FormEvent) => {
    setLoading(true);
    setErrorMessage('');

    try {
      for (const file of files) {
        const response = await generateUrlPromise(new EvidenceRequest(file.name));
        console.log('Generated URL:', response);
        await uploadAndRegisterVideoPromise(file, response.url, response.final_url,);
      }
    } catch (err) {
      console.error('Error during file processing:', err);
      setErrorMessage('Error en el envío de Datos de empleados.');
    } finally {
      setLoading(false);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return <>
    {showModal && (
      <SuccessModal
        message="Video enviado con éxito"
        buttonText="Continuar"
        onClose={handleCloseModal}
      />
    )}

    <div className="grid h-screen place-items-center place-content-center w-auto bg-[url('/src/assets/backgrounds/login-background.svg')] bg-repeat-y bg-center items-center p-4">
      <div className="grid justify-center text-center gap-5 items-center w-full bg-white rounded-lg shadow-lg border-2 border-secondaryBrown p-8">
        <div className="font-semibold p-5 text-center bg-secundaryYellow hover:bg-primaryYellow text-black rounded-md cursor-pointer w-full" {...getRootProps()}>
          <input className="w-82" {...getInputProps()} />
          {isDragActive ? <p>Suelta el archivo aqui...</p> : <p>Añadir video de la empresa</p>}
        </div>
        <div className="grid  place-content-around place-items-center w-max">
          {files.map((file, index) => (
            <video key={index} controls width="250">
              <source src={URL.createObjectURL(file)} type="video/mp4" />
              Tu navegador no soporta la etiqueta de video.
            </video>
          ))}
        </div>
        <div className="font-semibold border-1 bg-primaryGreen hover:bg-secondaryGreen text-black p-2 rounded-md cursor-pointer">
          <button onClick={handleClick}>Enviar</button>
        </div>
      </div>
      {loading && <p className="mt-2">Cargando...</p>}
      {errorMessage && <p>Error: {errorMessage}</p>}
    </div>
  </>;
}

export default AddVideos;
