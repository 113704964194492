import PlansCard from "../../components/plans/PlansCard";
import { Plan, plansService } from '../../services/plansService';
import { useEffect, useState } from "react";
import { mainDivClassName } from "../../utils/MainDivClassName";
import logoSrc from "../../assets/icons/logoLaborus.svg"


const Plans = () => {
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const fetchPlans = () => {
      plansService.listPlans(
        (response) => {
          setPlans(response);
        },
        (error) => {}
      );
    };

    fetchPlans();
  }, []);
  

  return (

    <div className={`grid h-screen ${mainDivClassName} p-2 pt-10`}>
      <div className="flex justify-center mb-10">
        <a href="https://laborus.co/" rel="noopener noreferrer">
          <img src={logoSrc} alt="Logo" className="h-auto w-auto" />
        </a>
      </div>
        <h2 className="text-2xl font-extrabold text-primaryOrange text-center mb-4">Obten un plan de Laborus</h2>

      <div className="flex gap-2 justify-center items-center space-x-4 tablet:space-x-0 tablet:flex-col mobile:flex-col mobile:space-x-0 ">
        {plans.map((plan) => (
          <PlansCard key={plan.id} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default Plans;


