import React, { useState, useEffect } from 'react';
import notificationsIcon from '../assets/icons/notifications-icon.svg';
import { companyService, NotificationData } from '../services/companyServices';

type Props = {};

const Notifications = (props: Props) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [expiringActivities, setExpiringActivities] = useState<NotificationData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    companyService.getNotifications(
      (response) => {
        setExpiringActivities(response.plans);
      },
      () => {
        setErrorMessage('No se pudo obtener notificaciones.');
      }
    );
  }, []);

  const notificationCount = expiringActivities.length;

  const handleClickNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  return (
    <div className="relative ">
      <button
        className="relative text-secondaryPink"
        onClick={handleClickNotifications}
      >
        <img src={notificationsIcon} alt="Notifications" className="w-14 h-14" />
        {errorMessage && (
          <span className="text-red-500 text-sm">{errorMessage}</span>
        )}
        {notificationCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 rounded-full">
            {notificationCount}
          </span>
        )}
      </button>
      {notificationsOpen && (
        <div className="absolute z-10 right-0 mt-4 w-96 bg-white border-2 text-secondaryBrown border-secondaryBrown rounded-lg shadow-lg p-4">
          <h3 className="text-xl font-semibold mb-2 text-primaryOrange ">
            Notificaciones
          </h3>
          <ol>
            {expiringActivities.length > 0 ? (
              expiringActivities.map((plan, index) => (
                <li
                  key={plan.id}
                  className="flex justify-between px-2 mb-2 gap-3"
                >
                  <div className="">
                    {index + 1}. {plan.activity}
                  </div>
                  <div className="text-secondaryOrange font-semibold">
                    {plan.status}
                  </div>
                </li>
              ))
            ) : (
              <li>No hay notificaciones</li>
            )}
          </ol>
        </div>
      )}
    </div>
  );
};

export default Notifications;
