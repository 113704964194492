import ItemMenu from './ItemMenu'
import homeIcon from '../../assets/icons/home-icon.svg'
import homeIconOpen from '../../assets/icons/home-icon-open.svg'
import absenteeismIcon from '../../assets/icons/absenteeism-icon.svg'
import absenteeismIconOpen from '../../assets/icons/absenteeism-icon-open.svg'
import workPlanIcon from '../../assets/icons/work-plan-icon.svg'
import workPlanIconOpen from '../../assets/icons/work-plan-icon-open.svg'
import trainingPlanIcon from '../../assets/icons/training-plan-icon.svg'
import trainingPlanIconOpen from '../../assets/icons/training-plan-icon-open.svg'
import employeesIcon from '../../assets/icons/employees-icon.svg'
import employeesIconOpen from '../../assets/icons/employees-icon-open.svg'
import logoutIcon from '../../assets/icons/logout-icon.svg'
import logoutIconOpen from '../../assets/icons/logout-icon-open.svg'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { authService } from '../../services/authServices';
import { setLoggedIn } from '../../features/userSlice';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const completeLogout = () => {
    dispatch(setLoggedIn(false));
    localStorage.clear();
    navigate('/');
  };

  const handleLogout = () => {
    authService.logout(
      completeLogout,
      (err) => {
        if (err?.response?.status === 401) {
          completeLogout();
        } else {
          alert(`Error durante cierre de sesión: ${err.message || err}`);
        }
      }
    );
  };

  return <div className="w-32 h-screen fixed top-0 left-0 flex-shrink-0 bg-white mobile:hidden">
    <div className="flex flex-col gap-5 p-4">
      <ItemMenu
        link="/"
        logo={homeIcon}
        selectedLogo={homeIconOpen}
        isSelected={location.pathname === '/' || location.pathname === '/home'}
      />
      <ItemMenu
        link="/absenteeism"
        logo={absenteeismIcon}
        selectedLogo={absenteeismIconOpen}
        isSelected={location.pathname === '/absenteeism'}
      />

      <ItemMenu
        link="/work-plan"
        logo={workPlanIcon}
        selectedLogo={workPlanIconOpen}
        isSelected={location.pathname === '/work-plan'}
      />
      <ItemMenu
        link="/training-plan"
        logo={trainingPlanIcon}
        selectedLogo={trainingPlanIconOpen}
        isSelected={location.pathname === '/training-plan'}
      />
      <ItemMenu
        link="/employees"
        logo={employeesIcon}
        selectedLogo={employeesIconOpen}
        isSelected={location.pathname === '/employees'}
      />
      <ItemMenu
        link="/logout"
        logo={logoutIcon}
        selectedLogo={logoutIconOpen}
        isSelected={location.pathname === '/logout'}
        onClick={handleLogout}
      />
    </div>
  </div>;
}

export default Sidebar;
