import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ItemMenuProps {
  logo: string;
  selectedLogo: string;
  link: string;
  isSelected: boolean;
  onClick?: () => void;
}

const ItemMenu: React.FC<ItemMenuProps> = (props) => {
  const { logo, selectedLogo, link, isSelected, onClick } = props;
  const navigate = useNavigate();

  return <div className={"flex justify-center text-center h-full w-full"}>
    <button
      className='flex h-full w-full justify-center items-center'
      onClick={onClick ? onClick : () => navigate(link)}
    >
      <img
        src={isSelected ? selectedLogo : logo}
        className='mr-2 rounded-lg'
        alt={`imagen${link}`}
      />
    </button>
  </div>;
}

export default ItemMenu;
