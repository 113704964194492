import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { handleInputValueChange } from '../../utils/SelectJobs';
import { EmployeesListData } from '../../services/employeesService';
import HeaderSurveys from '../HeaderSurveys';
import SelectInput from '../SelectInput';
import TextFieldString from '../TextFieldString';
import Button from '../Button';
import CancelButton from '../CancelButton';

interface CreateEmployeeModalProps {
  open: boolean;
  onClose: () => void;
  onCreateOrUpdate: (formData: CreateEmployeeFormData) => void;
  employee?: EmployeesListData;
  title: string;
}

export interface CreateEmployeeFormData {
  first_name: string;
  last_name: string;
  id_card: string;
  phone: string;
  email: string;
  role: string;
  job_description?: string;
  job_code?: number;
}

const roles = [
  { value: "system administrator", label: "Administrador del sistema" },
  { value: "legal representative or employer", label: "Representante legal o empleador" },
  { value: "worker", label: "Trabajador" },
];

const CreateEmployeeModal: React.FC<CreateEmployeeModalProps> = ({ open, onClose, onCreateOrUpdate, employee, title }) => {
  const [formData, setFormData] = useState<CreateEmployeeFormData>({
    first_name: '',
    last_name: '',
    id_card: '',
    phone: '',
    email: '',
    role: '',
    job_description: '',
    job_code: 0
  });

  const [errors, setErrors] = useState<CreateEmployeeFormData>({
    first_name: '',
    last_name: '',
    id_card: '',
    phone: '',
    email: '',
    role: '',
    job_description: '',
    job_code: 0
  });

  const [jobOptions, setJobOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (employee) {
      setFormData(employee);
      if (!jobOptions.find(option => option.label === employee.job_description)) {
        setJobOptions(prevOptions => [
          ...prevOptions,
          { label: employee.job_description, value: employee.job_code?.toString() || '' }
        ]);
      }
    }
  }, [employee, jobOptions]);
  



  const handleCreateOrUpdate = () => {
    const newErrors: CreateEmployeeFormData = {
      first_name: formData.first_name ? '' : 'Este campo es requerido',
      last_name: formData.last_name ? '' : 'Este campo es requerido',
      id_card: formData.id_card ? '' : 'Este campo es requerido',
      phone: formData.phone ? '' : 'Este campo es requerido',
      email: formData.email ? '' : 'Este campo es requerido',
      role: formData.role ? '' : 'Este campo es requerido',
      job_description: formData.job_description ? '' : 'Este campo es requerido',
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some(error => error !== '');
    if (!hasErrors) {
      onCreateOrUpdate(formData);
      setFormData({
        first_name: '',
        last_name: '',
        id_card: '',
        phone: '',
        email: '',
        role: '',
        job_description: '',
        job_code: 0
      });
      onClose();
    }
  };

  const handleJobChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    const job_description = selectedOption ? selectedOption.label : '';
    const job_code = selectedOption ? parseInt(selectedOption.value, 10) : undefined;

    setFormData((prev) => ({
      ...prev,
      job_description,
      job_code,
    }));
  };

  return  open ?  (
    <>
    <div className="fixed zin inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl border-2 border-secondaryBrown max-h-full overflow-y-auto mobile:max-h-screen">
          <HeaderSurveys title={`${title} empleado`} />
          <div className="grid text-start p-4 ">
            <div>
              <TextFieldString
                label="Nombre"
                value={formData.first_name}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    first_name: e.target.value,
                  }))
                }
              />
              {errors.first_name && (
                <span className="text-red-600 text-sm">{errors.first_name}</span>
              )}
            </div>

            <div>
              <TextFieldString
                label="Apellido"
                value={formData.last_name}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }))
                }
              />
              {errors.last_name && (
                <span className="text-red-600 text-sm">{errors.last_name}</span>
              )}
            </div>

            <div>
              <TextFieldString
                label="Cedula"
                value={formData.id_card}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    id_card: e.target.value,
                  }))
                }
              />
              {errors.id_card && (
                <span className="text-red-600 text-sm">{errors.id_card}</span>
              )}
            </div>

            <div>
              <TextFieldString
                label="Celular"
                value={formData.phone}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
              />
              {errors.phone && (
                <span className="text-red-600 text-sm">{errors.phone}</span>
              )}
            </div>

            <div>
              <TextFieldString
                label="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
              />
              {errors.email && (
                <span className="text-red-600 text-sm">{errors.email}</span>
              )}
            </div>

            <div>
              <SelectInput
                id="role"
                label="Rol"
                value={formData.role}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    role: e.target.value,
                  }))
                }
                options={roles}
              />
              {errors.role && (
                <span className="text-red-600 text-sm">{errors.role}</span>
              )}
            </div>

            <div className="w-full flex flex-col mb-4">
              <label htmlFor="job-description" className="mb-1 text-xl">
                Cargo
              </label>
              <Select
                className="rounded-lg"
                inputId="job-select"
                placeholder="Escribe para buscar cargos"
                name="job_description"
                options={jobOptions}
                value={jobOptions.find(
                  (option) => option.label === formData.job_description
                )}
                onChange={handleJobChange}
                onInputChange={(value: string) =>
                  handleInputValueChange(value, setJobOptions)
                }
                classNamePrefix={!!errors.job_description ? "react-select-error" : "react-select"}
                noOptionsMessage={() => "Escribe para buscar cargos"}
              />
              {errors.job_description && (
                <span className="text-red-600 text-sm">
                  {errors.job_description}
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-center gap-7 pb-4">
            <Button text="Guardar Cambios" onClick={handleCreateOrUpdate} />
            <CancelButton text="Cancelar" onClick={onClose} />
          </div>
        </div>
      </div>
    </>
  )  : null;
};

export default CreateEmployeeModal;
