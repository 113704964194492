/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { Card, Typography } from "@material-tailwind/react";
import AddImages from './AddImages';
import { EmployeesListData, employeesService } from '../../services/employeesService';
import { mainDivClassName } from '../../utils/MainDivClassName';
import HeaderSurveys from '../../components/HeaderSurveys';

type Props = {}
const TABLE_HEAD = ["Nombre", "Apellido", "Cédula", "Imágenes"];


const RegisterImages = (props: Props) => {
  const [employeesList, setEmployeesList] = useState<EmployeesListData[]>([]);

  useEffect(() => {
    employeesService.employeesData(
      (response) => {
        setEmployeesList(response.results)
      },
      (error) => {
        console.error('Error al obtener los datos:', error);
      },
      "Activo",
    );
  }, [])

  return (
    <>
      <div className="grid place-items-start w-full min-h-screen h-auto bg-no-repeat bg-cover  laptop:border laptop:border-white desktop:border desktop:border-white py-5" >
        <div className='w-full bg-white rounded-lg shadow-lg border-2 border-secondaryBrown'>
          <HeaderSurveys title={`Evidencia puestos de trabajos`} />
          <div className={`grid ${mainDivClassName} w-screen justify-around mt-3 text-xs`}>
            {employeesList &&
              <Card placeholder={" "} className="h-screen w-full overflow-scroll mt-4 mb-4">
                <table className="w-full min-w-max table-auto text-left overflow-auto h-full bg-white border-separate border border-spacing-x-2 border-transparent">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => (
                        <th key={head} className="text-center px-4 py-4 text-lg font-medium bg-secondaryGray text-secondaryBrown border-white rounded-lg">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                            placeholder={''}
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='text-secondaryBrown text-center'>
                    {employeesList.map(({ first_name, last_name, id_card, company, id }, index) => (
                      <tr key={`employee_${id}`} className="hover:bg-gray-50">
                        <td className="px-6 py-3 text-sm">
                          <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                            {first_name}
                          </Typography>
                        </td>

                        <td className="px-6 py-3 text-sm">
                          <Typography variant="small" color="blue-gray" className="font-normal" placeholder={''}>
                            {last_name}
                          </Typography>
                        </td>
                        <td className="px-6 py-3 text-sm">
                          <Typography variant="small" color="blue-gray" className="font-normal " placeholder={''}>
                            {id_card}
                          </Typography>
                        </td>
                        <td className="px-6 py-3 text-sm">
                          <AddImages employeeId={id} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default RegisterImages
