import axios from 'axios';

const API_URL =`https://www.datos.gov.co/resource/xdk5-pm3f.json` || '';

export interface Data {
    c_digo_dane_del_departamento: string;
    c_digo_dane_del_municipio: string;
    departamento: string;
    municipio: string;
  }

type OnSuccess = (data: Data[]) => void;

type OnError = (error: any) => void;

const listDaneData = (onSuccess: OnSuccess, onError: OnError): void => {
    axios.get<Data[]>(`${API_URL}`)
        .then(response => onSuccess(response.data)
        )
        .catch(error => onError(error)
        )
};

export const daneService = {
    listDaneData,
};
