import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { EvidenceImprovementPlanData, EvidenceRequest, EvidenceResponse, UploadRequest, uploadImageService } from '../../services/uploadImageServices';
import { ImprovementPlanData, autoevaluationService } from '../../services/autoevaluationService';
import CancelButton from '../CancelButton';
import Button from '../Button';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  itemText?: string;
  propName?: string;
  title?: string;
  id?: string;
  sx?: object;  
  employeeId?: number;
}

export const UploadEvidenceModal: React.FC<ModalProps> = ({ show, onClose, itemText, propName, employeeId, title, id, sx }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const generateUrlPromise = (request: EvidenceRequest): Promise<EvidenceResponse> => {
    return new Promise<EvidenceResponse>((resolve, reject) => {
      uploadImageService.generateUrl(request, (response: EvidenceResponse) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  };

  const uploadAndRegisterImagePromise = (file: File, url: string, finalUrl: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await uploadImageService.uploadImage(new UploadRequest(file, url),
          response => {
            console.log('Upload success:', response);
            if (propName === undefined) return;
            uploadImageService.evidenceImprovementPlans(new EvidenceImprovementPlanData(propName, finalUrl, employeeId),
              success => {
                console.log('Evidence plans success:', success);
                resolve(success);
              }, reject);
          }, reject);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleClickUpdate = () => {
    setShowOptions(true);
  };

  const handleClick = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      for (const file of files) {
        const response = await generateUrlPromise(new EvidenceRequest(file.name));
        console.log('Generated URL:', response);
        await uploadAndRegisterImagePromise(file, response.url, response.final_url);
      }
      setUploadSuccess(true);
    } catch (err) {
      console.error('Error during file processing:', err);
      setErrorMessage('Error en el envío de evidencia.');
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    setErrorMessage('');
    if (id === undefined) return;
    try {
      const data: ImprovementPlanData = {
        id: id,
        state: selectedOption,
      };
      autoevaluationService.putImprovementPlan(
        data,
        (response) => {
          console.log(response);
          alert("Actividad actualizada");
          handleClose();
          setLoading(false);
        },
        (error) => {
          console.error('Error al obtener los datos:', error);
          setLoading(false);
        }
      );
    } catch (err) {
      console.error('Error durante la actualización:', err);
      setErrorMessage('Error en la actualización de estado.');
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const renderPreview = (file: File) => {
    const fileType = file.type;
    if (fileType.startsWith('image/')) {
      return <img src={URL.createObjectURL(file)} alt={`preview-${file.name}`} style={{ width: '150px', height: '150px' }} />;
    } else if (fileType === 'application/pdf') {
      return (
        <embed src={URL.createObjectURL(file)} type="application/pdf" width="150px" height="150px" />
      );
    } else {
      return (
        <div className="p-2 border rounded bg-gray-200" style={{ width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
          {file.name}
        </div>
      );
    }
  };

  const handleClose = () => {
    setLoading(false);
    setErrorMessage('');
    setFiles([]);
    setUploadSuccess(false);
    setShowOptions(false);
    setSelectedOption('');
    onClose();
  };

  if (!show) return null;

  return (
    <>
      <div className="fixed inset-0 grid items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none border-2 border-secondaryBrown"
        style={{ zIndex: 1400, ...sx }} 
      >
        <div className="relative w-auto mx-auto max-w-3xl my-6">
          <div className="flex flex-col w-full bg-white rounded-lg shadow-lg outline-none focus:outline-none">
            <div className="flex items-start text-center justify-between p-5 border-b border-solid border-gray-200 rounded-t">
              <div className="text-lg text-secondaryBrown">Subir evidencia {title}</div>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleClose}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
              </button>
            </div>
            <div className="relative flex-auto p-6">
              <div>
              <div className="bg-secundaryYellow hover:bg-primaryYellow font-semibold text-secondaryBrown p-5 text-center" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? <p>Suelta el archivo aquí ...</p> : <p>Añadir Evidencia</p>}
                </div>
                <div className="grid grid-cols-3 place-content-center place-items-center w-max">
                  {files.map((file, index) => (
                    <div key={index} className="p-2">
                      {renderPreview(file)}
                    </div>
                  ))}
                </div>
                <span className='text-red-600'>{errorMessage}</span>
              </div>
              <div className="flex items-center justify-center gap-4 p-6 border-t border-solid border-gray-200 rounded-b">
               
                {!uploadSuccess ? (
                  <Button
                    text= {loading ? 'Subiendo...' : 'Subir Evidencia'}
                    onClick={handleClick}
                    disabled={loading}
                  />
                ) : (
                  <Button
                    onClick={handleClickUpdate}
                    text='Actualizar estado de la actividad'
                  />
                )}
                 <CancelButton
                  text='Cerrar'
                  onClick={handleClose}
                />
              </div>
            </div>
            {showOptions && (
              <div className='px-8 pb-8'>
                <RadioGroup value={selectedOption} onChange={handleOptionChange} className='mb-2'>
                  <FormControlLabel value="Cumple" control={<Radio />} label="Cumple" />
                  <FormControlLabel value="Cumple parcialmente" control={<Radio />} label="Cumple parcialmente" />
                </RadioGroup>
                <Button 
                  onClick={handleAccept}
                  text='Aceptar'
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-35" style={{ zIndex: 1350 }}></div>
    </>
  );
};
